class ValidationResult {
    /**
     * @param {string} key
     * @param {string} value
     * @param {boolean} isValid
     * @param {string} path - Тип валидации или пусть к сервису валидации
     */
    constructor(key, value, isValid, path) {
        this.key = key;
        this.value = value;
        this.isValid = isValid;
        this.path = path;
    }

    get json() {
        const {key, value, isValid, path} = this;
        return {key, value, isValid, path};
    }
}

class Context {
    constructor() {
        this.validations = [];
    }

    /**
     *
     * @param {string} key
     * @param {string} value
     * @param {boolean} isValid
     * @param {string} path - Тип валидации или пусть к сервису валидации
     */
    addValidationResult(key, value, isValid, path) {
        //Если это url - удаляются параметры после ?
        if(path && path.includes("?")) path = path.split("?")[0];

        this.validations.push(new ValidationResult(key, value, isValid, path));
    }

    /**
     * @return {{}} - JSON объект для отправки на сервер. Отправляем только события для email и phone
     */
    get json() {
        return {
            validations: this.validations.filter(v => ["/validate/email", "/validate/phone"].includes(v.path)).map(v => v.json)
        }
    }
}

export default Context;