/**
 * UBE plugin to check if idx docs check passed
 * @param sessionKey { String } - UBE session key
 * @param callback { Function } - callback function to get result of check
 * @param interval { Number } - interval between start and end of requests in seconds
 * @param timeout { Number } - interval between getInfo queries in seconds
 */
$.fn.ubeCheckAV = function (sessionKey, callback, interval, timeout) {
    const getInfo = () => $.get(`${$.ube.host}/esb/iqos-redesign-idx-cabinet/cabinet?sessionKey=${sessionKey}`)
        .done(response => {
            if(response && response.checkDocsIDX) {
                switch (response.checkDocsIDX) {
                    case 'CHECK_NOT_STARTED':
                        callback('none');
                        break;
                    case 'CHECK_STARTED':
                        callback('pending')
                        break;
                    case 'CHECK_SUCCESS':
                        callback('success')
                        break;
                    case 'CHECK_FAIL':
                        callback('fail');
                        break;
                    case 'CHECK_MANUAL':
                        callback('manual');
                        break;
                }
            }
        })
        .fail(() => {
            console.log('UBE getInfo Error')
        })
    if(!sessionKey) {
        console.log('UBE Check AV session key is not presented');
        return;
    }
    if(!callback) {
        console.log('UBE Check AV callback is not presented');
        return;
    }
    if(!interval || !timeout) {
        getInfo()
    } else {
        const now = new Date();
        const dateAfterTimeout = new Date(now.getTime() + interval * 1000);

        const timer = setInterval(function () {
            if(new Date().getTime() < dateAfterTimeout.getTime()) {
                getInfo()
            } else {
                clearInterval(timer);
            }
        }, timeout * 1000)
    }
}