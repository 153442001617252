import Field from './field'

class Factory {

    #fieldNames;
    #fieldsComponentMap;
    #fieldsInstanceMap;
    #container;
    #components;
    #options;

    constructor(container, components, options) {
        this.#container = container;
        this.#components = components;
        this.#options = options;

        this.#fieldNames = [];
        this.#fieldsInstanceMap = {};
        this.#fieldsComponentMap = {};

        components.forEach(component => {
            this.#fieldNames.push(component.key);
            this.#fieldsInstanceMap[component.key] = new Field(this, component.key);
            this.#fieldsComponentMap[component.key] = component;
        });

        this.field = this.field.bind(this);
        this.component = this.component.bind(this);
        this.hasField = this.hasField.bind(this);
        this.getField = this.getField.bind(this);
        this.setFieldValue = this.setFieldValue.bind(this);
        this.getFieldValue = this.getFieldValue.bind(this);
        this.fieldNamesByTag = this.fieldNamesByTag.bind(this);
        this.autoCreateAbsentFields = this.autoCreateAbsentFields.bind(this);
    }

    get container() {
        return this.#container;
    }

    get options() {
        return this.#options;
    }

    get fieldNames() {
        return  this.#fieldNames;
    }

    get fieldMap() {
        return this.#fieldsComponentMap;
    }

    fieldNamesByTag(tag) {
        if (!tag || tag === "") return [];
        return this.#components
            .filter(component => component.tags && component.tags.indexOf(tag) > -1)
            .map(x => x.key);
    }

    field(key) {
        if(!this.#fieldNames.includes(key)) throw new Error(`Unknown field key ${key}`);
        return this.#fieldsInstanceMap[key];
    }

    component(key) {
        if(!this.#fieldNames.includes(key)) throw new Error(`Unknown component key ${key}`);
        return this.#fieldsComponentMap[key];
    }

    /**
     * Checks that form has field with such key
     * @param key
     * @return {boolean}
     */
    hasField(key) {
        return !!this.fieldMap[key];
    }

    getField(key, filter) {
        return this.field(key).getDOMField(filter);
    }

    setFieldValue(key, value) {
        return this.field(key).setDOMFieldValue(value);
    }

    /**
     *
     * @param {string} key - field key
     * @param {any} defaultValue - value if DOM field is absent
     * @returns {*|any}
     */
    getFieldValue(key, defaultValue) {
        const field = this.field(key);
        const common = function (key) {
            return field.getDOMFieldValue(defaultValue);
        };
        const custom = this.options.getFieldValue;
        return (custom || common)(key, common);
    }

    autoCreateAbsentFields() {
        this.fieldNames.forEach(key => this.field(key).createFieldIfAbsent());
    }
}

export { Factory as default }