import { Block } from "./block";
import { gaPush } from "../../../util/ga";

export class Content extends Block {
    run(key, state, cb) {
        gaPush('dl_qr_content_start', '');
        this.options.skuContent(() => {
            gaPush('dl_qr_content_end', '');
            cb({ userLoggedIn: state.login },
                {
                    sku: true,
                    skuShown: true
                }
            )
        }, state);
    }

    close() {
        $('.content-screen').hide();
    }
}
