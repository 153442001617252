(function () {
    $.fn.ubeLeadWidget = function (name, options) {
        $.ube = $.ube || {};
        $.ube.showRules = options.showRules && options.showRules;
        $.ube.hideRules = options.hideRules && options.hideRules;
        $.ube.toggleLoader = options.toggleLoader && options.toggleLoader;
        $.ube.showPopup = options.showPopup && options.showPopup;
        $.ube.host = options.host || $.ube.host;

        function showPopup(message) {
            if ($.ube && $.ube.showPopup) $.ube.showPopup(message)
            else alert(message);
        }

        if (!options.onLeadCreate) {
            const message = `UBE: отсуствует обязательный параметр onLeadCreate: function(next) {...}`
            console.error(message);
            showPopup(message);
        }

        $(this).ube(name, {
            isTemplatePreloaded: false,
            onFormLoad: function() {
                options.onFormLoad && options.onFormLoad();
            },
            onSubmissionSuccess: function (submissionId, leadId, result) {
                switch (result.event) {
                    case "created":
                        options.onLeadCreate();
                        break;
                }
            }
        })
    }
})();
