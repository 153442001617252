import { Block } from "./block";

export class FailContent extends Block {
    run(key, state, cb) {
        this.options.failContent(() => {
            cb({ target: 'inner' })
        }, state);
    }

    close() {
        $('.fail-screen').hide();
    }
}
