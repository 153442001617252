const ubeCookie = function (key, value, expires) {
    if (key && value === undefined) {
        var parts = ("; " + document.cookie).split("; " + key + "=");
        return parts.length === 2 ? parts.pop().split(";").shift() : undefined;
    } else if (key && value === null) {
        document.cookie = encodeURIComponent(key) + '=' + encodeURIComponent(value) + '; expires=' + new Date(0).toUTCString() + "; path=/; secure;";
    } else if (key) {
        if (!expires) {
            var now = new Date()
            expires = new Date(now.setMonth(now.getMonth() + 13)).toUTCString()
        }

        document.cookie = encodeURIComponent(key) + '=' + encodeURIComponent(value) + '; expires=' + expires + "; path=/; secure;";
    }
};

const sessionExpiration = () => new Date(new Date().getTime() + (30 * 60 * 1000)).toUTCString();

const getCookiesArray = () => {
    const cookies = document.cookie.split(';');
    let response = [];

    response = cookies.map(cookie => {
        cookie = cookie.split('=');
        const key = cookie[0].trim();
        const value = cookie[1].trim();

        return {
            [key]: value
        }
    })

    return response;
}

const getGA4UserId = () => {
    var cookiesArray = getCookiesArray();
    var ga4UserIdObject = cookiesArray.find(x => x['ga4'] && x['ga4'].startsWith('GA1.2.'));
    var ga4UserId;

    if(ga4UserIdObject && ga4UserIdObject['ga4']) {
        ga4UserId = ga4UserIdObject['ga4'].slice(6);
    }

    return ga4UserId;
}

const getGA4SessionId = () => {
    var ga4SessionId;
    var cookiesArray = getCookiesArray();

    cookiesArray.forEach(x => {
        if(x) {
            const [key] = Object.keys(x);

            if(key && x[key].startsWith('GS1.')) {
                var parts = x[key].split('.');

                if (parts[1]) {
                    if (!ga4SessionId) {
                        ga4SessionId = +parts[2];
                    }

                    if (ga4SessionId < +parts[2]) {
                        ga4SessionId = +parts[2];
                    }
                }
            }
        }
    })

    return ga4SessionId;
}

const cookies = {
    UBE_SESSION_KEY: "ube_session_key",
    UBE_FACE_BEFORE: "ube_face_before",
    UBE_COOKIE_POLICY: "UBE_COOKIE_POLICY",
    UBE_COOKIE_CHECK: "UBE_COOKIE_CHECK",
    UBE_SESSION_KEY_REG: "UBE_SESSION_KEY_REG",
    UBE_AGE_VERIFIED_TOKEN: "UBE_AGE_VERIFIED_TOKEN",
    UBE_AGE_VERIFIED: "UBE_AGE_VERIFIED",
    UBE_JWT_FACE_TOKEN: "UBE_JWT_FACE_TOKEN",
    UBE_WIDGET_SESSION_KEY: "UBE_WIDGET_SESSION_KEY",
    UBE_WIDGET_REGISTER: "UBE_WIDGET_REGISTER",
    UBE_WIDGET_LOGIN: "UBE_WIDGET_LOGIN",
    UBE_DOCUMENTS_PROVIDED: "UBE_DOCUMENTS_PROVIDED"
};

export {ubeCookie, cookies,getGA4UserId,getGA4SessionId, sessionExpiration};