import { Block } from "./block";

export class Redirect extends Block {
    run(key, state, cb) {
        const { toggleLoader, redirect } = this.options;
        toggleLoader && toggleLoader(true);
        const redirectOptions = {
            allocationCode: redirect.allocation,
            redirect: redirect.page,
            formKey: this.name,
            sessionKey: state.sessionKey
        };

        if (redirect.external) redirectOptions.external = redirect.external;

        $.ubeLink(redirectOptions);
    }

    close() {
        $('.ube-form-docs').hide();
    }
}
