import { Block } from "./block";

export class Start extends Block {
    run(key, state, cb) {
        $('.start-screen').show();
        this.options.startContent((userFlow) => {
            userFlow ? cb({userFlow}) : cb();
        });
    }

    close() {
        $('.start-screen').hide();
    }
}
