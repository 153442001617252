import platform from "../libraries/platform";

class Device {

    #deviceResolution;
    #deviceModel;
    #deviceTimezone;

    calculateDeviceResolution() {
        this.#deviceResolution = window.screen.width + "X" + window.screen.height
    }

    calculateDeviceModel() {
        this.#deviceModel = platform.product ? platform.product : "unknown"
    }

    calculateDeviceTimezone() {
        this.#deviceTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    get getDeviceData() {
        return {
            deviceResolution: this.#deviceResolution,
            deviceModel: this.#deviceModel,
            deviceTimezone: this.#deviceTimezone
        }
    }
}

export { Device as default }