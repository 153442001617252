import { toggleLoader } from './toggleLoader';

function acceptTerms(sessionKey, entity, termsCode, apiUrl) {
  var data = {
      sessionKey: sessionKey,
      termsCodesAndVersions: termsCode
  };

  return new Promise(function (resolve, reject) {
    $.ajax({
        url: apiUrl + '/session/' + entity + '/acceptTerms',
        data: JSON.stringify(data),
        type: "POST",
        processData: false,
        cache: false,
        dataType: "json",
        contentType: 'application/json',
        crossDomain: true,
        error: function (err) {
            toggleLoader(false);

            return reject(err);
        },
        success: function (data) {
            toggleLoader(false);

            return resolve(data);
        }
    });
  });
}

export { acceptTerms };