import {gaPush} from "./ga";

// TODO removed heatingtech due to temporary domain shutdown. Rollback when needed
const oneTrustDomains = {
  'cloud.bondstreet.ru': 'ac7af100-4974-4ed1-8814-516589dc4ddd',
  'cloud.dailyscan.ru': 'af810e28-6edf-47c1-b99b-9886d07e8d24',
  'cloud.lmlab.ru': 'a489c8a9-75a4-4117-808c-26bdecec6195',
  'cloud.marlboro.ru': 'e16de826-8ca5-461c-8c72-40e3efda2b01',
  'cloud.mrphilipmorris.ru': '23d22c70-1869-4ce5-9b80-916c7a09628b',
  'cloud.mychesterfield.ru': 'b890b37e-4d0f-474e-aa41-41fb90c343ff',
  'cloud.nextlook.ru': '2b5af8d2-ac74-46c0-aece-13deede72930',
  'cloud.parliament.ru': 'd83a9f77-9c14-4584-b6fb-b8c8aeef262a',
  'cloud.pmbrands.ru': '4d003004-d5b0-4759-a077-f619d4022bf2',
  'cloud.premium.one': '514dc8ba-b8bd-4692-95a4-3db150d1744b',
  'qr.bondstreet.ru': '2db2f75c-9e49-4476-b540-9c7d5b7e853d',
  'qr.lmlab.ru': '01be202e-1f24-4da5-980a-2a6873a13b7c',
  'qr.marlboro.ru': 'b4d703f7-6c2c-4ac5-9601-f2bee6b5e7a7',
  'qr.mrphilipmorris.ru': '8db745de-d8b6-4adc-8ec5-8a7ca4214e07',
  'qr.mychesterfield.ru': '3470246f-4d82-4adc-ae42-1e5165ea06a3',
  'qr.nextlook.ru': '6144c970-2507-495a-9d4d-e91817e38a05',
  'qr.parliament.ru': 'b2cdb729-7f38-44f8-ace2-6fefed2e4acd',
  'bondstreet.ru': 'ae0f2ccc-49bc-4b45-bcfd-ef1658061551',
  'dailyscan.ru': '25ea1a4a-f068-49fc-b620-327c634d5d85',
  'lmlab.ru': 'b385c893-bbdc-4819-92a4-e20e675f9f1b',
  'marlboro.ru': 'bc223e4b-2065-43ed-a6d8-2150c350e131',
  'mrphilipmorris.ru': '9632b110-44b0-4a2a-b0ec-4d973ed14b4f',
  'mychesterfield.ru': '03217fae-e963-4480-9d99-83355a5dfb8b',
  'nextlook.ru': 'fa487714-8f39-40d8-bdf6-70b1b7a3dba6',
  'nng.iqos.ru': 'e64426ed-657b-4b87-969f-b0942655515f',
  'parliament.ru': 'b0bd4b5c-a907-4bed-80f7-90d997f6bc46',
  'premium.one': 'b2eb3e21-d616-47b3-9933-82abbff6577b',
}

function oneTrustInit () {
  if (!window.Optanon && !window.OptanonWrapper) {
    const domain = Object.keys(oneTrustDomains).find(x => window.location.hostname.includes(x));

    if (domain) {
      console.log('UBE One Trust start loading...');
      var s = document.createElement("script");
      var l = document.createElement("link");

      s.type = "text/javascript";
      s.src = `https://wcmn.myizhora.com/common-init.js?siteid=${oneTrustDomains[domain]}`;
      l.type = "text/css";
      l.rel = "stylesheet";
      l.href = "https://wcmn.myizhora.com/common-default.css";

      document.body.append(s);
      document.head.append(l)

      s.onload = function () {
        console.log('UBE One Trust loaded');

        $(document).on("mousedown", '#_pmiz_common_consentAccept', function() {
          gaPush('dl_cookie', 'accept')
        })
      }

      return true;
    }
  }
}

export { oneTrustInit }
