import Fingerprint from "./fingerprint/Fingerprint";
import Device from "./device/Device";
import { getQueryParameter } from "./util/url";

function getGAId() {
    let gaId;
    try {
        gaId = ga.getAll()[0].get('clientId');
    } catch (e) {
        console.error("UBE AF: Google Analytics not found")
    }
    return gaId;
}

const UBE_HOST = new URL(document.currentScript.src).host;

function getScript(scriptUrl, callback) {
    const script = document.createElement('script');
    script.src = scriptUrl;
    script.onload = callback;

    document.body.appendChild(script);
}

function executeCaptcha(cb, repeats = 4) {
    try {
        grecaptcha.execute('6LcXBdEZAAAAAHJnopGOUJ1DQls4rhdvUz-8Oy8i', {action: 'registration'})
            .then(function (token) {
                cb(token);
            })
    } catch (error) {
        if (error.message && error.message.includes('not loaded in api.js')) {
            getScript("https://www.google.com/recaptcha/api.js?render=6LcXBdEZAAAAAHJnopGOUJ1DQls4rhdvUz-8Oy8i", () => {
                setTimeout(() => {
                    if (repeats > 0) executeCaptcha(cb, repeats - 1)
                    else console.error("UBE AF: " + error.message);
                }, 500)
            });
        } else {
            console.error(error);
        }
    }
}

function validateCaptchaV3(cb) {
    try {
        if (grecaptcha) {
            grecaptcha.ready(function () {
                executeCaptcha(cb)
            });
        } else {
            const message = "Google ReCaptcha library not found or broken";
            console.error("UBE AF: " + message);
            cb(undefined, message);
        }
    } catch (e) {
        if (window.location.host.includes('force.com')) {
            cb(undefined, message);
        } else {
            getScript("https://www.google.com/recaptcha/api.js?render=6LcXBdEZAAAAAHJnopGOUJ1DQls4rhdvUz-8Oy8i", () => {
                validateCaptchaV3(cb);
            });
        }
        
        const message = "Google ReCaptcha exception: " + (e && e.message || e);
        console.error("UBE AF: " + message);
        console.log('UBE :: loading recaptcha v3 API...');
    }
}

/**
 * Calculate fingerprint data
 * @param [key] - optional ube form key
 * @param [token] - optional recaptcha v3 token
 * @returns {Promise<{c: string, a:string, f: string, r: string, m: string, z: string, h: string, g: string, token?: string, tokenError?: string}>}
 */
function calculate(key, token) {
    return new Promise(function(callback) {
        const fingerPrint = new Fingerprint();
        const device = new Device();

        fingerPrint.calculateAudioFingerprint();
        fingerPrint.calculateCanvasBase64();
        fingerPrint.calculateFontFingerprint();

        device.calculateDeviceResolution();
        device.calculateDeviceModel();
        device.calculateDeviceTimezone();

        setTimeout(() => {
            const hashParams = fingerPrint.fingerprintData;
            const deviceParams = device.getDeviceData;
            const data = {
                c: hashParams.c,
                a: hashParams.a,
                f: hashParams.f,
                r: deviceParams.deviceResolution,
                m: deviceParams.deviceModel,
                z: deviceParams.deviceTimezone,
                h: window.location.href,
                g: getGAId(),
                key: key
            }

            const scanToken = getQueryParameter(window.location.search, 'scanToken');

            if (scanToken) {
                data.scanToken = scanToken;
            }

            const toReturn = { ...data };

            function afterCaptcha(token, error) {
                if (token) toReturn.token = token;
                else if (error) toReturn.tokenError = error;
                callback(toReturn);
            }

            if (!token) {
                validateCaptchaV3(afterCaptcha);
            } else {
                toReturn.token = token
                callback(toReturn);
            }
        }, 500)
    });
}

/**
 * Write captcha v3 score to storage
 * @param data
 * @returns data
 */

function setCaptchaScore(data) {
    data && data.captchaScore && sessionStorage.setItem('impressionScore', data.captchaScore);
    return data;
}

/**
 * Send fingerprint data to collect impression token
 * @param data
 * @returns {Promise<{token: string}>}
 */
function send(data) {
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch(`https://${UBE_HOST}/api/impression/token`, options)
        .then(response => response.json())
        .catch(e => {
            console.error("UBE AF: Error requesting Impression API");
            console.error(e);
            throw e;
        })
}

var isImpressionStarted = false;

window.Impression = {
    /**
     * Write impression token in ube
     * @param [key] - optional ube form key
     */
    runImpression: function (key) {
        if(key && !window.location.host.includes('force.com') && !$.ube.impressionToken && !isImpressionStarted) {
            this.getToken(key)
            .then(res => {
                $.ube.impressionToken = res;
                isImpressionStarted = true;
            })
            .catch(e => console.log(e))
        }
    },

    /**
     * Get Impression token
     * @param [key] - optional ube form key
     * @param [token] - optional recaptcha v3 token
     * @returns {Promise<string>}
     */
    getToken: function (key, token) {
        return calculate(key, token)
            .then(send)
            .then(setCaptchaScore)
            .then(data => data && data.token)
    }
}