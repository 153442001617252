var captchaV3Domains = 'iqos.ru|nng|mrphilipmorris.ru|marlboro.ru|bondstreet.ru|lmlab.ru|nextlook.ru|parliament.ru|premium.one|dailyscan.ru|mychesterfield.ru|localhost';

const UBE_PROD_HOST = 'https://ube.pmsm.org.ru';

const checkPhoneWL = (phone, successCb, errorCb) => {
    const { host } = $.ube;
    if (host === UBE_PROD_HOST) return errorCb();
    return $.get(`${host}/api/f2s/consumer/phone?phone=${phone}`)
        .then(res => res.noCaptcha ? successCb() : errorCb())
}
function randomId() {
    return "ube-" + Math.random().toString(36).substr(2);
}

function initFormCaptcha(container, captchaType) {
    let captchaId = randomId();

    if(captchaType === 'google') {
        if (!window.grecaptcha) $.getScript("https://www.google.com/recaptcha/api.js?render=explicit");
        container.attr("withReCaptcha", captchaId);
        container.find(".g-recaptcha").first().attr("id", captchaId);
    } else {
       if(!window.smartCaptcha) $.getScript("https://smartcaptcha.yandexcloud.net/captcha.js");
        container.attr("withYandexCaptcha", captchaId);
        container.find(".yandex-captcha").first().attr("id", captchaId);
    }
}

function validateYandexCaptcha(container, callback) {
    let captchaId = container.attr("withYandexCaptcha");
    if(captchaId) {
        let widgetId = container.data("captchaWidgetId");
        if (widgetId == undefined || widgetId == null) {
            let sitekey = sessionStorage.getItem('sitekey');
            widgetId = smartCaptcha.render(captchaId, {
                sitekey,
                invisible: true,
                hideShield: true,
                callback
            });
            container.data("captchaWidgetId", widgetId);
        } else smartCaptcha.reset(widgetId);
        smartCaptcha.execute(widgetId);
    } else {
        callback()
    }
}

function validateCaptcha(container, callback) {
    var recaptchaId = container.attr("withReCaptcha");
    if (recaptchaId) {
        var widgetId = container.data("recaptchaWidgetId");
        if (widgetId == undefined || widgetId == null) {
            widgetId = grecaptcha.render(recaptchaId, {
                callback: callback
            });
            container.data("recaptchaWidgetId", widgetId);
        } else grecaptcha.reset(widgetId);
        grecaptcha.execute(widgetId);
    } else {
        callback();
    }
}

//UBE-294 Исправление проблемы google captcha callback - всегда вызывается только первый callback
function getGlobalCapchaCallback(localCallback) {
    window.ubeCaptchaCallback = localCallback;

    if(!window.ubeGlobalCaptchaCallback) window.ubeGlobalCaptchaCallback = function(proof) {
        if(window.ubeCaptchaCallback) window.ubeCaptchaCallback(proof);
    };

    return window.ubeGlobalCaptchaCallback;
}

function validateSetCaptchaProof(container, data, captchaType, dataCallback) {
    const dataCopy = Object.assign({}, data);

    if (data && data.data && data.data.phone) {
        if(captchaType === 'google') {
            checkPhoneWL(data.data.phone, dataCallback.bind(null, dataCopy), validateCaptcha.bind(null, container, getGlobalCapchaCallback(callback)));
        } else {
            checkPhoneWL(data.data.phone, dataCallback.bind(null, dataCopy), validateYandexCaptcha.bind(null, container, callback));
        }
    } else {
        if(captchaType === 'google') {
            validateCaptcha(container, getGlobalCapchaCallback(callback));
        } else {
            validateYandexCaptcha(container, callback);
        }
    }
    function callback(proof) {
        if(proof) dataCopy.grecaptcha = proof;
        validateCatchaV3(dataCopy, dataCallback);
    }
}

function validateCatchaV3(data, dataCallback) {
    if (location.hostname.match(captchaV3Domains)) {
        grecaptcha.ready(function () {
            grecaptcha.execute('6LcXBdEZAAAAAHJnopGOUJ1DQls4rhdvUz-8Oy8i', { action: 'registration' }).then(function(token) {
                data.grecaptchaV3 = token;
    
                if (dataCallback) {
                    dataCallback(data);
                }
            });
        });
    } else {
        if (dataCallback) {
            dataCallback(data);
        }
    }
}

function initFormCaptchaV3() {
    $.getScript("https://www.google.com/recaptcha/api.js?render=6LcXBdEZAAAAAHJnopGOUJ1DQls4rhdvUz-8Oy8i");
}

export { validateCaptcha, validateSetCaptchaProof, captchaV3Domains, initFormCaptchaV3, initFormCaptcha }
