import $ from 'jquery';
import {isAppleDevice} from "../util/device";

$.fn.ubeAutocomplete = function (opts) {
    const field = $(this);

    const defaultAutocompleteOpts = {
        minLength: 1,
        open: function (result) {
            if (isAppleDevice) $('.ui-autocomplete').off('menufocus hover mouseover');

            if (opts.classes && opts.classes["ui-autocomplete"] && opts.classes["ui-autocomplete"] == 'max-height') {
                $('.ui-autocomplete.max-height').css({
                    maxHeight: '250px',
                    overflowY: 'scroll'
                });
            }
        }
    };

    const overrideOptions = {};

    const position = $(this).attr("data-position");
  	if(position !== "off") {
    	defaultAutocompleteOpts.position = position === "top" ?
            {my: "left bottom", at: "left top", collision: "flip"} : {my: "left top", at: "left bottom", collision: "flip"};
    }

    var appendToTarget = $(this).attr("data-target") ? $($(this).attr("data-target")) : $(this).closest("form");

    if(appendToTarget && appendToTarget.length === 1) defaultAutocompleteOpts.appendTo = appendToTarget;

    function setDataSource(source) {
        field.data("source", source);
    }

    //UBE-787 Set data source on response
    if(opts.source) {
        if(Array.isArray(opts.source)) setDataSource(opts.source);
        else if(typeof opts.source === "function") {
            overrideOptions.source = function(request, response) {
                opts.source(request, function(result) {
                    setDataSource(result);
                    response(result);
                })
            }
        }
    }

    return $(this).autocomplete($.extend(defaultAutocompleteOpts, opts, overrideOptions)).disableBrowserAutocomplete();
};
