import $ from 'jquery';

$.tgLink = function (options) {
    let { utmSource, utmCampaign, referralFormKey, url, isAuth } = options;
    
    function canParse(x) {
        try {
           new URL(x);
           return true;
        } catch (e) {
          return false;
        }
      }

	if(!url) alert('$.tgLink отстутствует параметр url');

    if(!canParse(url)) alert('$.tgLink некорректный формат ссылки url: ' + url);

    if(!utmSource ) alert('$.tgLink отстутствует параметр utmSource');
    if(!utmCampaign ) alert('$.tgLink отстутствует параметр utmCampaign');

    let jwtFaceToken = ubeCookie('UBE_JWT_FACE_TOKEN');
    let impression = $.ube.impressionToken;

    if(!url.includes('?')) url += '?';

    let tgLink = url && `${url}&utm_source=${utmSource}&utm_campaign=${utmCampaign}&tokenfr=${ubeCookie('UBE_AGE_VERIFIED_TOKEN') || false}`;

    if (jwtFaceToken) tgLink += `&jwtFaceToken=${jwtFaceToken}`;
    if (impression) tgLink += `&impression=${impression}`;
    if (referralFormKey) tgLink += `&referralFormKey=${referralFormKey}`;

    if(!canParse(tgLink)) alert('$.tgLink некорректный формат ссылки')

    if (!isAuth) {
        window.location.href = new URL(tgLink);
    } else {
        $.ubeLink({
            external: true,
            callback: function (link) {
                if (link) {
                    let token = link.match(/T=[^&]*/)[0].slice(2);
					if(token) {
                        tgLink += `&token=${token}`;
                    } else {                
						console.error('$.tgLink получен пустой token от сервера');
                    }
                    window.location.href = new URL(tgLink);                    
                }
            }
        })
    }
}
