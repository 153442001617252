import $ from 'jquery';

$.fn.ubeTraverseEnter = function () {
    var form = $(this);
    var selector = form.find('.ube-traverse-enter').length > 0 ?
        '.ube-traverse-enter' : 'input';
    form.find(selector).keydown(function (e) {
        var fields = form.find(selector + ':visible');
        if (e.which === 13) {
            var index = fields.index(this) + 1;
            var length = fields.length;
            if ($(this).is(".ube-wizard-enter-next")) {
                form.trigger("ubeNext");
                e.preventDefault();
                return false;
            }
            else if (index < length) {

                fields.eq(index).focus();
                e.preventDefault();
                return false;
            }
        }
    });
};
