class Timer {

    constructor() {
        this.fields = {};
        this.start = new Date().getTime();
        this.end = undefined;
        this.focus = this.focus.bind(this);
        this.blur = this.blur.bind(this);
        this.ends = this.ends.bind(this);
    }

    /**
     * Input field gets focused - focus event
     * @param {string} key
     */
    focus(key) {
        if (!key) return;
        if (!this.fields[key]) this.fields[key] = {};
        this.fields[key]['start'] = new Date().getTime() - this.start;
    }

    /**
     * Consumer end field intput end leaves it - blur event
     * @param {string} key
     */
    blur(key) {
        if (!key) return;
        if (!this.fields[key]) this.fields[key] = {};
        this.fields[key]['end'] = new Date().getTime() - this.start;
    }

    /**
     * Provides timer data to submit to backend
     * @return {{start: number, end: number, fields: {}}}
     */
    ends() {
        this.end = new Date().getTime() - this.start;
        return {
            start: this.start,
            end: this.end,
            fields: this.fields
        }
    }
}

export default Timer;