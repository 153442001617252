import $ from 'jquery';
import {ubeHostFallBack} from '../util/fallback';
import {isNoCameraStream} from '../util/device';

(function () {

  function showPopup(message) {
    if ($.ube && $.ube.showPopup)
      $.ube.showPopup(message)
    else {
      console.log(message);
      alert(message);
    }
  }

  function urlToFile(url) {
    return fetch(url)
        .then(res => res.blob())
        .then(blob => {
          return new File([blob], "File name",{ type: "image/png" })
        })
  }

  function invert(ctx) {
    ctx.globalCompositeOperation='difference';
    ctx.fillStyle='white';
    ctx.fillRect(0,0,ctx.canvas.width,ctx.canvas.height);
  }

  function rotate(canvas) {
    const ctx = canvas.getContext("2d");
			ctx.translate(canvas.width / 2, canvas.height / 2); // translate to canvas center
			ctx.rotate(Math.PI * 0.5); // add rotation transform
			ctx.globalCompositeOperation = "copy"; // set comp. mode to "copy"
			ctx.drawImage(ctx.canvas, 0, 0, canvas.width, canvas.height, -canvas.width / 2, -canvas.height / 2, canvas.width, canvas.height);
      ctx.translate(-canvas.width / 2, -canvas.height / 2);
  }

  function contrast(ctx, contrast) {
    let imgData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
    let d = imgData.data;
    contrast = (contrast/100) + 1;  //convert to decimal & shift range: [0..2]
    let intercept = 128 * (1 - contrast);
    for(let i=0;i<d.length;i+=4){   //r,g,b,a
      d[i] = d[i]*contrast + intercept;
      d[i+1] = d[i+1]*contrast + intercept;
      d[i+2] = d[i+2]*contrast + intercept;
    }
    ctx.putImageData(imgData, 0, 0);
  }

  /**
   *
   * @param {string} name
   * @param {{getDataMatrix: function, getImages: function?, debugTarget: string?, returnCanvas: boolean?, cropWidth: number?, cropHeight: number?, interval: number?}} options
   */
  $.fn.ubeCameraCHZ = function (name, options) {
    const defaultOptions = {
      cropWidth: 400,
      cropHeight: 400,
      interval: 500,
      returnCanvas: true,
      cameraTimeoutSeconds: 20,
      scanFormat: 'DATA_MATRIX',
      errorMessage: 'DM не распознан, попробуй сделать фотографию более четкой в более светлом месте'
    };
    options = { ...defaultOptions, ...(options || {})};
    const html5QrCode = new Html5Qrcode("reader", {formatsToSupport: [Html5QrcodeSupportedFormats[options.scanFormat]]});

    function scanFile(imageToScan, images, stop) {
      return urlToFile(imageToScan).then(imageFile => 
        html5QrCode.scanFile(imageFile, false)
            .then(decodedText => {
              // success, use decodedText
              $("#result").text(decodedText);

              console.log("Decoded text:", decodedText);
              return options.getDataMatrix(decodedText, images, stop)
                .then(() => decodedText)
            })
      )
    }

    function prepareImages(canvas) {
      const ctx = canvas.getContext("2d");
      contrast(ctx, 30);
      // rotate the canvas to the specified degrees
      const imagesToScan = [];
      // get images on all four sides as leading side
      for (let i = 1; i < 5; i++) {
        let img = canvas.toDataURL('image/png', 1);
        imagesToScan.push(img);
        invert(ctx);
        img = canvas.toDataURL('image/png', 1);
        imagesToScan.push(img);
        rotate(canvas);
      }
      return imagesToScan;
    }

    options.intervalCallback = function(canvas, stop) {
      const imagesToScan = prepareImages(canvas);
      scanImages(imagesToScan, stop);
    }

    options.getPhotoBase64 = function (canvas, stop) {
      const imagesToScan = prepareImages(canvas);
      scanImages(imagesToScan, stop, () => {
        showPopup(options.errorMessage)
      })
    }

    options.timeoutCallback = function(stop) {
      return options.timeout(stop)
    }

    function scanImages(imagesToScan, stop, errorCb) {
      // check all images recursively
      (function process(index) {
        if (index >= imagesToScan.length) {
          if (errorCb) errorCb();
          return;
        }
        scanFile(imagesToScan[index], imagesToScan, stop)
          .then(function(decodedText) {
            if(!decodedText) process(index + 1);
          })
          .catch(err => {
            console.log(`Error scanning file. Reason: ${err}`)
            if (options.getImages) options.getImages(imagesToScan, stop);
            process(index + 1);
          })
      })(0);
    }

    return $(this).ubeCamera2(name, options);
  }

  /**
   *
   * @param {string} name
   * @param {{intervalCallback: function?, debugTarget: string?, getPhotoBase64: function?, returnCanvas: boolean?, cropWidth: number?, cropHeight: number?, interval: number?}} options
   */
  $.fn.ubeCamera2 = function (name, options) {
    var container = $(this).first();
    var form = container.find("form");
    let { getPhotoBase64 } = options;

    function debug(text) {
      console.log(text);
      if(options.debugTarget) {
        $(options.debugTarget).append("<p>"+text+"</p>");
        if($(options.debugTarget).find('p').length > 5) $(options.debugTarget).find('p').first().remove();
      }
    }

    ubeHostFallBack();

    function loadTemplate() {
      const host = $.ube.host;
      const template_url = options.template || (host + "/form/" + name);
      $.get(template_url)
        .then(res => {
          container.html(res.template);
          initModule();
        })
    }

    function initCamera() {
      container.find(".ube-visibility-show-for-method, .ube-visibility-show-for-faceMethod").hide();
      container.find(".ube-visibility-show-for-camera").show();

      initializeCameraCapture();
    }

    function initializeCameraCapture() {
      $(".ube-camera-error").hide();
      $(".ube-camera-container").show();
      var mode;
      var renderContainer = container.find(".ube-camera-container");
      var renderTarget = container.find(".ube-camera-render");
      var captureButton = container.find(".ube-camera-capture");
      var fallbackTarget = container.find(".ube-camera-fallback");
      var shadeContainer = container.find(".ube-camera-shade");
      if (renderTarget.data("init")) return false;
      renderTarget.data("init", true);

      function createDummyCanvas() {
        return $("<canvas style='visibility:hidden;display:block;position: absolute;top:-2000px;left:-2000px'></canvas>").appendTo("body")[0];
      }

      function removeCanvas(canvas) {
        setTimeout(function() {
          $(canvas).remove();
        }, 1000)
      }

      function drawScanBorders() {
        const SCAN_AREA_WIDTH = 70;
        const SCAN_AREA_HEIGHT = 70;
        const videoRenderWidth = $('.ube-camera-render').width();
        const videoRenderHeight = $('.ube-camera-render').height();
        const borderYAxisWidth = (videoRenderHeight - SCAN_AREA_HEIGHT) / 2 + 'px';
        const borderXAxisWidth = (videoRenderWidth - SCAN_AREA_WIDTH) / 2 + 'px';

        $('.ube-camera-shade').css('borderWidth', borderYAxisWidth + ' ' + borderXAxisWidth);
      }

      function formatResultBase64(base64, cb) {
          const stopCapture = () => {};
          const canvas = createDummyCanvas();
          const image = new Image();
          image.onload = function() {
            if(options.cropWidth && image.width > options.cropWidth && options.cropHeight && image.height > options.cropHeight) {
              const canvasWidth = Math.min(options.cropWidth, image.width);
              const canvasHeight = Math.min(options.cropHeight, image.height);
              canvas.width = canvasWidth;
              canvas.height = canvasHeight;
              canvas.getContext('2d').drawImage(image, (image.width - canvasWidth)/2, (image.height - canvasHeight)/2, canvasWidth, canvasHeight, 0, 0, canvasWidth, canvasHeight);
            } else {
              const canvasWidth = image.width;
              const canvasHeight = image.height;
              canvas.width = canvasWidth;
              canvas.height = canvasHeight;
              canvas.getContext('2d').drawImage(image, 0, 0);
            }
            cb(canvas, stopCapture);
          };
          image.src = base64;
      }

      function formatResultCanvas(canvas) {
        if(options.returnCanvas) return canvas;
        else return canvas.toDataURL('image/png', 1);
      }

      function renderCameraCapture() {
        if(isNoCameraStream) return renderFileUpload();
        fallbackTarget.hide();
        mode = "stream";
        renderTarget.html("<video></video>").show();
        renderContainer.show().addClass("ube-camera-option-capture").removeClass("ube-camera-option-upload");
        const video = renderTarget.find("video")[0];

        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {

          video.setAttribute('autoplay', '');
          video.setAttribute('muted', '');
          video.setAttribute('playsinline', '');

          var constraints = {
            audio: false,
            video: {
              facingMode: 'environment',
              height: { ideal: 4096 },
              resizeMode: 'crop-and-scale'
            }
          };

          var stopVideoCapture = function() {
            debug("stopVideoCapture()");
            video.pause();
            video.src = "";
            if (video.srcObject) {
              video.srcObject.getTracks().forEach(function(track) {track.stop()});
            }
            shadeContainer.hide();
            renderTarget.html("").data("init", false).hide();
            renderContainer.hide();
          };

          form.on("stopVideoCapture", function () {
            debug("Handling stop function");
            stopVideoCapture();
          });

          function greyScale(ctx) {
            let imgData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
            let pixels = imgData.data;
            for (let i = 0; i < pixels.length; i += 4) {

              let lightness = parseInt((pixels[i] + pixels[i + 1] + pixels[i + 2]) / 3);

              pixels[i] = lightness;
              pixels[i + 1] = lightness;
              pixels[i + 2] = lightness;
            }
            ctx.putImageData(imgData, 0, 0);
          }

          navigator.mediaDevices.getUserMedia(constraints)
            .then(function (stream) {
              video.srcObject = stream;
              debug("Video capture init success");
              const {width, height} = stream.getTracks()[0].getSettings();
              debug(`${width}x${height}`); // 640x480

              const track = stream.getVideoTracks()[0];
              const capabilities = track.getCapabilities();

              const settings = stream.getVideoTracks()[0].getSettings();
              debug(JSON.stringify(settings));
              debug(JSON.stringify(capabilities));

              if (capabilities.zoom) {
                //включаем аппаратный зум
                debug('Hardware zoom: ' + capabilities.zoom.max);
              } else {
                debug('No hardware zoom');
              }

              function readVideoFrame(canvas) {
                debug("Capturing video");

                if(options.cropWidth && video.videoWidth > options.cropWidth && options.cropHeight && video.videoHeight > options.cropHeight) {
                  const canvasWidth = Math.min(options.cropWidth, video.videoWidth);
                  const canvasHeight = Math.min(options.cropHeight, video.videoHeight);
                  canvas.width = canvasWidth;
                  canvas.height = canvasHeight;
                  canvas.getContext('2d').drawImage(video, (video.videoWidth - canvasWidth)/2, (video.videoHeight - canvasHeight)/2, canvasWidth, canvasHeight, 0, 0, canvasWidth, canvasHeight);

                } else {
                  const canvasWidth = video.videoWidth;
                  const canvasHeight = video.videoHeight;
                  canvas.width = canvasWidth;
                  canvas.height = canvasHeight;
                  canvas.getContext('2d').drawImage(video, 0, 0);
                }
                debug(canvas.width);
                debug(canvas.height);
                // Other browsers will fall back to image/png

                //const ctx = canvas.getContext('2d');
                //greyScale(ctx);
                //contrast(ctx, 50);
                //invert(ctx);
                return canvas;
              }

              if(options.intervalCallback) {
                debug('Init interval capture');
                let canvas = createDummyCanvas();
                let attemptsCount = 0;
                const scanIntervalMS = options.interval || 500;
                const attemptsCountLimit = options.cameraTimeoutSeconds * 1000 / scanIntervalMS;
                let intervalId = setInterval(function(){
                  if (attemptsCount > attemptsCountLimit) {
                    $('body').on('click', '.ube-reload', function() {
                      container.find(".ube-visibility-show-for-method, .ube-visibility-show-for-faceMethod").hide();
                      container.find(".ube-visibility-show-for-camera").show();
                    })
                    if (options.timeout) options.timeoutCallback(function() {
                      //Stop function
                      removeCanvas(resultCanvas);
                      stopVideoCapture();
                      clearInterval(intervalId);
                    })
                    return;
                  }
                  drawScanBorders();
                  // Other browsers will fall back to image/png
                  const resultCanvas = readVideoFrame(canvas);
                  options.intervalCallback(formatResultCanvas(resultCanvas), function() {
                    //Stop function
                    removeCanvas(resultCanvas);
                    stopVideoCapture();
                    clearInterval(intervalId);
                  });
                  attemptsCount++;
                }, options.interval || 500);

              }

              captureButton.off("click").click(function (e) {
                e.preventDefault();
                const canvas = createDummyCanvas()
                const imageData = readVideoFrame(canvas);

                if(getPhotoBase64) {
                  getPhotoBase64(imageData)
                } else if(options.intervalCallback) {
                  options.intervalCallback(imageData);
                }

                removeCanvas(canvas);
                return false;
              });

            })
            .catch(function (e) {
              debug("Capture video error 1");
              renderFileUpload();
            });
        } else {
          debug("Capture video error 2");
          renderFileUpload();
        }

      }

      function renderFileUpload() {
        mode = "upload";
        renderContainer.removeClass("ube-camera-option-capture").addClass("ube-camera-option-upload");
        fallbackTarget.show();
        renderTarget.hide();
        shadeContainer.hide();

        var dummyFile = $("<input type=\"file\" accept=\"image/*\" capture=\"user\" style='width:0;height:0;position:absolute;'/>").appendTo("body");

        dummyFile.off("change").change(function () {
          debug("File field changed");
          var reader = new FileReader();

          reader.addEventListener("load", function () {
            var imageData = this.result;
            console.log("Image data:");
            console.log(imageData);

            if(getPhotoBase64) {
              formatResultBase64(imageData, getPhotoBase64)
            }

            dummyFile.val("")
            dummyFile.wrap('<form>').closest('form').get(0).reset();
            dummyFile.unwrap();
          }, false);

          reader.readAsDataURL(this.files[0]);
        });

        captureButton.add(".ube-camera-option-upload").off("click").click(function (e) {
          console.log("Capture upload clicked");
          e.preventDefault();
          dummyFile.click();
          return false;
        });
      }

      if (options.mode === 'camera') renderCameraCapture();
      else renderFileUpload();
    }

    function initModule() {
      $(".ube-visibility-show-for-method").show();
      $(".ube-method-button").on('click', function() {
        options.mode = $(this).data('option');
        initCamera();
      })
    }

    if (options.isTemplatePreloaded) {
      initModule()
    } else {
      loadTemplate()
    }
  };
})();
