import { cookies, ubeCookie } from "../../util/cookie";
import { gaPush } from "../../util/ga";
import { toggleLoader } from "../../util/toggleLoader";
import { create } from "./factory";

(function() {
    $.fn.ubeWidget = function(name, options) {
        const container = $(this).first();
        const faceContainer = $('<div class="ube-face-container screen"></div>').appendTo(container);
        const loginContainer = $('<div class="ube-login-container screen"></div>').appendTo(container);
        let timeoutEntity;

        if(!ubeCookie(cookies.UBE_AGE_VERIFIED_TOKEN)) localStorage.removeItem(cookies.UBE_AGE_VERIFIED_TOKEN);
        $.ube = $.ube || {};
        $.ube.showRules = options.showRules && options.showRules;
        $.ube.hideRules = options.hideRules && options.hideRules;
        $.ube.toggleLoader = options.toggleLoader && options.toggleLoader;
        $.ube.showPopup = options.showPopup && options.showPopup;
        $.ube.host = options.host || $.ube.host;

        Impression.runImpression(name);

        if (!options.classes) {
            $('.screen').hide().filter('.ube-screen-start').show();
        } else {
            faceContainer.addClass(options.classes.face[0]);
            loginContainer.addClass(options.classes.login[0]);
        }

        function showPopup(message) {
            if ($.ube && $.ube.showPopup)
                $.ube.showPopup(message)
            else {
                alert(message);
            }
        }

        const mandatoryParams = ['startContent', 'skuContent', 'finalContent'];

        mandatoryParams.forEach(param => {
            if (!options[param]) {
                const message = `UBE: отсуствует обязательный параметр ${param}: function(next) {...}`
                console.error(message);
                showPopup(message);
            }
        })


        /**
         * @type {{
        * event: string,
        * result: Object,
        * avStatus: number,
        * skuShown: boolean,
        * originalEvent: string,
        * faceRecoPassed: boolean
        * }} globalCtx
        */
       let globalCtx = {};

        /**
         * @type {{
         * previousBlock: string, 
         * customUJ: array, 
         * face: boolean,
         * shouldSkipFace: boolean,
         * login: false,
         * ujType: string,
         * ujTemplate: string,
         * skuShown: boolean,
         * redirect: Object,
         * av: number,
         * faceAV: number,
         * faceReco: Object,
         * sessionKey: string,
         * phone: string,
         * withOptin: boolean
         * }} state
         */
        let state = {
            previousBlock: '',
            customUJ: [],
            face: false,
            shouldSkipFace: false,
            login: false,
            ujType: '',
            ujTemplate: '',
            skuShown: false,
            sku: false,
            faceAV: 0,
            av: 0,
            redirect: {
                autoInit: false,
                manualInit: false,
                source: '',
            }
        }
        $.get(`${$.ube.host}/form/${name}/?journey=true`)
            .then(res => {
                if (res.enabled) state.ujTemplate = res.templateName;
                if (state.ujTemplate === 'reversed') {
                    state.av = 0;
                    state.face = true;

                    const expires = "expires=Thu, 01 Jan 1970 00:00:00 UTC";
                    ubeCookie(cookies.UBE_AGE_VERIFIED_TOKEN, '', expires);
                    ubeCookie(cookies.UBE_AGE_VERIFIED, '', expires);
                    ubeCookie(cookies.UBE_FACE_BEFORE, '', expires);
                    try {
                        if (localStorage) {
                            localStorage.removeItem(cookies.UBE_AGE_VERIFIED_TOKEN);
                            localStorage.removeItem(cookies.UBE_AGE_VERIFIED);
                            localStorage.removeItem(cookies.UBE_FACE_BEFORE);
                        }
                    } catch (error) {
                        window.backupLocalStorage.removeItem(cookies.UBE_AGE_VERIFIED_TOKEN);
                        window.backupLocalStorage.removeItem(cookies.UBE_AGE_VERIFIED);
                        window.backupLocalStorage.removeItem(cookies.UBE_FACE_BEFORE);
                    }
                }
            });
        $.get(`${$.ube.host}/api/scanpack/userJourney/${name}`)
            .then(res => {
                if (res.journey) {
                    if(res.journey.find(x => x.key === 'emailOptinNode')) state.withOptin = true;

                    state.customUJ = res.journey;
                    $('.ube-face-container').remove();
                    $('.ube-login-container').remove();
                    startCustomUJ();
                }
                else options.startContent(next);
            })
            .catch(() => {
                options.startContent(next);
            });

        function skipFace() {
            const jwtToken = ubeCookie(cookies.UBE_JWT_FACE_TOKEN);

            if (jwtToken) {
                $.post(`${$.ube.host}/api/session/validate/token`, { jwt: jwtToken })
                    .done(response => {
                        if (response.result) {
                            state.shouldSkipFace = true;
                            state.face = true;
                            state.faceAV = 1;
                        }
                    })
                    .fail((xhr, status, error) => {
                        console.log("UBE :: JWT validation error");
                        console.log(xhr, status, error);
                    })
            };

        }

        skipFace();

        const startContent = () => {
            gaPush('dl_qr_content_start', '');
            state.faceAV = 1;
            state.skuShown = true;
            options.skuContent(next, {
                faceRecoPassed: true
            })
        }

        const processDefaultUJ = () => {
            if (state.login) {
                if (state.faceAV === 1 ||
                    state.faceAV === 0 && state.av === 0 ||
                    state.faceAV === 0 && state.av === 1 && state.skuShown) {
                    gaPush('dl_qr_content_end', '');
                    startRedirect()
                } else {
                    if (!options.classes) {
                        $('.screen').hide();
                    } else {
                        loginContainer.addClass(options.classes.login[1]);
                    }

                    if (state.faceAV === 0 && state.av === 1 && !state.skuShown) {
                      gaPush('dl_qr_content_start', '');
                    }

                    state.skuShown = true;
                    options.skuContent(next, {
                        ...globalCtx,
                        faceRecoPassed: state.faceAV
                    });
                }
            } else if (state.face) {
                if (!state.skuShown && state.ujTemplate !== 'reversed' && state.faceAV) {
                    if (state.shouldSkipFace) {
                        sessionStorage.setItem('isFaceSkipped', true);
                        gaPush('dl_qr_av_fr_skip', 'click - skip - form - fr');
                    }

                    startContent();
                } else {
                    if (state.av || state.faceAV) gaPush('dl_qr_content_end', '');
                    startLogin();
                }
            }
            else startFaceReco();
        }

        const processCustomUJ = result => {
            const block = result && result.block || {};
            const currentState = result && result.state || state;
            const nextBlockKey = getNextBlock(block, currentState);
            nextBlockKey && runBlockRecursive(nextBlockKey, currentState);
        }

        const next = res => {
            if (state.customUJ.length > 0) processCustomUJ(res || { })
            else processDefaultUJ();
        }

        const startRedirect = () => {
            const { redirect } = options;

            if (!redirect) {
                console.warn('UBE - не указан параметр redirect');
                return;
            }

            const initializeRedirect = () => {
                options.toggleLoader(true);
                const redirectOptions = {
                    allocationCode: redirect.allocation,
                    redirect: redirect.page,
                    formKey: name
                };

                if (redirect.external) redirectOptions.external = redirect.external;

                $.ubeLink(redirectOptions)
            }

            if (redirect.auto) {
                if (!state.redirect.autoInit) {
                    state.redirect.autoInit = true;
                    const timeoutInMS = parseInt(redirect.auto) || 3000;
                    timeoutEntity = setTimeout(() => {
                        initializeRedirect();
                    }, timeoutInMS)
                } else {
                    clearTimeout(timeoutEntity);
                    initializeRedirect();
                }
            } else if (state.redirect.source !== 'sku' && !state.redirect.manualInit) {
                state.redirect.manualInit = true;
                return;
            } else {
                initializeRedirect();
            }
        }

        const startFaceReco = () => {
            gaPush('dl_qr_av_fr_process', 'view - screen_fr');
            toggleLoader(true)
            state.face = true;
            if (!options.classes) {
                $('.screen').hide();
                faceContainer.show();
            } else {
                faceContainer.removeClass(options.classes.face[0])
            }
            faceContainer.ubeScanpackFace(name, result => {
                if (!options.classes) {
                    faceContainer.hide();
                } else {
                    faceContainer.addClass(options.classes.face[1])
                }
                if (result) {
                    sessionStorage.setItem('isFaceSkipped', state.shouldSkipFace)
                    startContent();
                } else if (options && options.failContent) {
                    options.failContent(next, {
                        faceRecoPassed: false
                    })
                } else {
                    startLogin();
                }
            })
        }

        const startLogin = () => {
            if (!options.classes) {
                $('.screen').hide();
                loginContainer.show();

            } else {
                loginContainer.removeClass(options.classes.login[0]);
            }

            loginContainer.ubeScanpackLogin(name, (event, result, originalEvent, avStatus) => {
                globalCtx = {
                    ...globalCtx,
                    event,
                    result,
                    originalEvent,
                    avStatus,
                    faceRecoPassed: state.faceAV,
                    skuShown: state.skuShown,
                };

                state.login = true;
                state.av = avStatus;
                state.ujEvent = originalEvent;
                if (!state.skuShown) state.redirect.source = 'sku';

                options.finalContent(next, globalCtx);
                if (options.classes) {
                    loginContainer.addClass(options.classes.login[1])
                } else {
                    loginContainer.hide();
                }

                if (avStatus && state.skuShown) {
                    startRedirect();
                }
            }, { idx: state.faceAV === 0, trial: options.trial })
        }

        const startCustomUJ = () => runBlockRecursive(state.customUJ[0].key, state);
        
        const runBlockRecursive = (key, state) => {
            runBlock(key, state, (block, state) => {
                const nextBlockKey = getNextBlock(block, state);
                nextBlockKey && runBlockRecursive(nextBlockKey, state);
            })
        }

        const getNextBlock = (block, state) => {
            let nextBlockId;
            const { nextBlock, data } = state.customUJ.find(item => item.key === state.previousBlock);

            if (nextBlock) nextBlockId = nextBlock
            else if (data.conditions && Array.isArray(data.conditions) && data.conditions.length > 0) {
                const nextBlockCondition = data.conditions.find(item => item.key == Object.entries(block)[0][0] && item.value == Object.entries(block)[0][1]);
                nextBlockId = nextBlockCondition.next;
            }

            const targetBlock = state.customUJ.find(item => item.id === nextBlockId);

            return targetBlock ? targetBlock.key : '';
        }

        const runBlock = (key, state, cb) => {
            state.currentNodeId = state.customUJ.find(item => item.key === key).id;
            create(key, name, options, container).runBlock(key, state, cb);
        }
    }
})();
