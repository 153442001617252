import { cookies, ubeCookie } from "../../../util/cookie";
import { Block } from "./block";
import { getInfo, authBySocial } from "../../../util/login";
import { gaPush } from "../../../util/ga";

export class PhoneSend extends Block {
    run(key, state, cb) {
        const options = this.options;
        const target = this.initContainers('login', 'phone-send');
        const isDocuments = options && options.documents;
        const isIDXEnabled = !state.faceAV;
        const phoneSendName = this.name.replace("-reg", "-phone-send");
        const isFRCookieExists = ubeCookie(cookies.UBE_JWT_FACE_TOKEN) || false;
        const isTrial = options && options.trial;
        const data = (options && options.data) || {};
        const formKey = this.name;
        const PhoneSendParams = state.customUJ.find(x => x.id === state.currentNodeId);
        const logins = {
            vk: PhoneSendParams?.params?.loginThroughVK,
            yandex: PhoneSendParams?.params?.loginThroughYA
        };

        let user;

        const formData = {
            ...data,
            phone: "",
        }

        function handleSocialAuth(formKey, socialName) {
            authBySocial({
                    formKey,
                    socialName,
                    successCB: (result) => {
                        onSocialAuthSuccess(result);
                    },
                    errorCB: (error) => {
                        $.ube.showPopup(error.message);
                        options.toggleLoader && options.toggleLoader(false);
                    }
                }
            );
        }

        function onSocialAuthSuccess(result) {
            const block = {
                loginStatus: '',
            };
            const state = {
                login: true,
                sessionKey: result.sessionKey,
                event: 'login',
                result,
                originalEvent: 'login',
                user: result?.user,
            }

            if(!result?.user?.phone) {
                $(target).find('[name="sessionKey"]').val(result?.sessionKey);
                if(result.user) user = result.user;
                $('.ube-auth-vk, .ube-auth-yandex').hide();
                options.toggleLoader && options.toggleLoader(false);
                options.showPopup && options.showPopup('Добавьте номер телефона');
                return;
            }

            switch (result.event) {
                case "authorization_not_found":
                    if (isIDXEnabled) {
                        block.loginStatus = 'verified-av0';
                        state.av = 0;
                        state.avStatus = 0;
                    } else {
                        block.loginStatus = 'verified-av1';
                        state.av = 1;
                        state.avStatus = 1;
                    };
                    cb(block, state)
                    options.toggleLoader && options.toggleLoader(false);
                    break;

                case 'authorization_success':
                    ubeCookie(cookies.UBE_WIDGET_LOGIN, 'Y');
                    getInfo(result.sessionKey, formKey)
                        .done(response => {
                            const { av, trialAccess } = response;
                            const avStatus = +av;
                            state.avStatus = avStatus;
                            state.av = avStatus;
                            if (isIDXEnabled || isDocuments || isTrial) {
                                if (avStatus === 1 || (isTrial && avStatus === 0 && trialAccess)) {
                                    console.log("UBE Login result", response);
                                    if (trialAccess) console.log("UBE :: user has trial access");
                                    block.loginStatus = 'loginSuccess-av1';
                                } else {
                                    block.loginStatus = 'loginSuccess-av0';
                                }
                            } else {
                                block.loginStatus = 'loginSuccess-av' + avStatus;
                            }
                            options.toggleLoader && options.toggleLoader(false);
                            cb(block, state);
                        })
                    break;
            }
        }

        target.show().ube(phoneSendName, {
            data: formData,
            isTemplatePreloaded: false,
            onFormLoad: function () {
                for(let login in logins) {
                    if(logins[login]) {
                        $(`.ube-auth-${login}`).on('click', function () {
                            options.toggleLoader && options.toggleLoader(true);
                            handleSocialAuth(formKey, login);
                        });
                    }
                }

                if ((isDocuments || isIDXEnabled) && !isFRCookieExists) {
                    $('.ube-av0-phone-send, .ube-av0-phone-check').show();
                    $('.ube-av1-phone-send, .ube-av1-phone-check').hide();
                } else {
                    $('.ube-av1-phone-send, .ube-av1-phone-check').show();
                    $('.ube-av0-phone-send, .ube-av0-phone-check').hide();
                }
                options.onFormLoad && options.onFormLoad(key);
            },
            onSubmissionSuccess: function (submissionId, userId, result) {
                const state = {
                    phone: result.data.phone,
                    sessionKey: result.data.sessionKey
                }

                if(user) state.user = user;

                switch (result.event) {
                    case 'success':
                        cb({
                            loginStatus: 'phoneCheck'
                        }, state);
                        break;
                }
            }
        });
    }

    close() {
        $('.ube-form-phone-send').hide();
    }
}
