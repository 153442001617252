import $ from 'jquery';
import '../impression';
import {ubeHostFallBack} from '../util/fallback';
import {ubeCookie, cookies} from '../util/cookie';
import {gaPush} from "../util/ga";
import { isNoCameraStream } from '../util/device';
import { faceErrors, errorMap } from '../util/faceTexts';
import { getImageSize, imageDataToBlob, logACSResultToELK, resizeImage } from '../util/faceHelpers';

(function () {

    function toggleLoader(visible) {
        console.log("UBE Toggle loader " + visible);
        if ($.ube && $.ube.toggleLoader) $.ube.toggleLoader(visible);
    }

    function showPopup(message) {
        if ($.ube && $.ube.showPopup)
            $.ube.showPopup(message);
        else {
            console.log(message);
            alert(message);
        }
    }

    function getToken(callback, repeat) {
        const MAX_REPEAT_COUNT = 4;
        const getTimeout = repeat => 2**(MAX_REPEAT_COUNT - repeat) * 1000

        const host = $.ube.host;
        if(!repeat) repeat = MAX_REPEAT_COUNT;
        $.getJSON(host + "/api/face/token").done(function (data) {
            toggleLoader(false);
            console.log("Token response:");
            console.log(data);

            if(!data.access_token || !data.next_validation_endpoint) {
                console.log("Not token or endpoint in response");
                repeat = repeat - 1;
                if (repeat > 0) setTimeout(() => getToken(callback, repeat), getTimeout(repeat))
                else {
                    gaPush("AV", "dl_qr_av_fr_fail", "form - fr - reason: Get-Token-Error-UBE");
                    showPopup("Ошибка в ответе сервиса ACS API");
                }
            } else {
                gaPush("dl_qr_av_fr_start", "form - fr");
                callback(data.access_token, data.next_validation_endpoint);
            }

        }).fail(function (jqxhr, textStatus, error) {
            var err = textStatus + ", " + error;
            console.log("Token request Failed: " + err);
            repeat = repeat - 1;
            if (repeat > 0) setTimeout(() => getToken(callback, repeat), getTimeout(repeat))
            else {
                gaPush("AV", "dl_qr_av_fr_fail", "form - fr - reason: Get-Token-Error-ACS");
                showPopup("Ошибка в ответе сервиса ACS API");
            }
        })
    }

    function validateToken(token, captureType, callback, repeat) {
        const host = $.ube.host;
        if(!repeat) repeat = 3;
        $.ajax({
            url: host+"/api/face/token",
            method: "post",
            data: JSON.stringify({token, captureType}),
            contentType: 'application/json; charset=UTF-8'
        }).done(function (data) {
            toggleLoader(false);
            console.log("Token validation response:");
            console.log(data);
            callback(data);
        }).fail(function (jqxhr, textStatus, error) {
            var err = textStatus + ", " + error;
            console.log("Token validation Failed: " + err);
            repeat = repeat - 1;
            if (repeat > 0) validateToken(token, captureType, callback);
            else showPopup("Ошибка при валидации результатов ACS API");
        })
    }

    $.fn.ubeFace = function (name, options) {
        let promoCampaignName;
        var container = $(this).first();
        let tryCount = 1;
        var b64Img = '';
        var form = container.find("form");
        window.backupLocalStorage = {};
        window.backupLocalStorage.setItem = function(key, value) {
            window.backupLocalStorage[key] = value;
        }
        window.backupLocalStorage.getItem = function(key) {
            return window.backupLocalStorage[key];
        }
        window.backupLocalStorage.removeItem = function(key) {
            delete window.backupLocalStorage[key];
        }
        ubeHostFallBack();

        const host = $.ube.host;

        function onError(event, data) {
            if (event === 'limit') {
                const expires = "expires=Thu, 01 Jan 1970 00:00:00 UTC";
                ubeCookie(cookies.UBE_AGE_VERIFIED_TOKEN, '', expires);
                ubeCookie(cookies.UBE_AGE_VERIFIED, '', expires);
                ubeCookie(cookies.UBE_FACE_BEFORE, '', expires);
                try {
                    if (localStorage) {
                        localStorage.removeItem(cookies.UBE_AGE_VERIFIED_TOKEN);
                        localStorage.removeItem(cookies.UBE_AGE_VERIFIED);
                        localStorage.removeItem(cookies.UBE_FACE_BEFORE);
                    }
                } catch (error) {
                    window.backupLocalStorage.removeItem(cookies.UBE_AGE_VERIFIED_TOKEN);
                    window.backupLocalStorage.removeItem(cookies.UBE_AGE_VERIFIED);
                    window.backupLocalStorage.removeItem(cookies.UBE_FACE_BEFORE);
                }
            }
            if(options.onSubmissionError) options.onSubmissionError(event, data);
        }

        function onSuccess(token, captureType) {
            validateToken(token, captureType, function(result) {
                if (result && result.ga) {
                    if (result.ga instanceof Array)
                        result.ga.forEach(function (ga) {
                            gaPush(ga.category, ga.action, ga.label);
                        });
                    else if (result.ga.action) {
                        var ga = result.ga;
                        gaPush(ga.category, ga.action, ga.label);
                    }
                }

                if(result && result.data) {
                    if(result.data.sessionKey) {

                        const d = new Date();
                        const faceDate = new Date();
                        let expires = null;
                        let faceExpires = null;
                        d.setTime(d.getTime() + (50*60*1000));
                        faceDate.setTime(faceDate.getTime() + (120*60*1000));
                        expires = "expires="+ d.toUTCString();
                        faceExpires = "expires="+ faceDate.toUTCString();
                        ubeCookie(cookies.UBE_SESSION_KEY_REG, result.data.sessionKey, expires);
                        ubeCookie(cookies.UBE_FACE_BEFORE, true);
                        ubeCookie(cookies.UBE_AGE_VERIFIED_TOKEN, token, faceExpires);
                        ubeCookie(cookies.UBE_AGE_VERIFIED, "face", faceExpires);
                        try {
                            if (localStorage) {
                                localStorage.setItem(cookies.UBE_FACE_BEFORE, true);
                                localStorage.setItem(cookies.UBE_AGE_VERIFIED_TOKEN, token);
                                localStorage.setItem(cookies.UBE_AGE_VERIFIED, "face");
                            }
                        } catch (error) {
                            window.backupLocalStorage.setItem(cookies.UBE_FACE_BEFORE, true);
                            window.backupLocalStorage.setItem(cookies.UBE_AGE_VERIFIED_TOKEN, token);
                            window.backupLocalStorage.setItem(cookies.UBE_AGE_VERIFIED, "face");
                        }

                    }
                    if(options.onSubmissionSuccess) options.onSubmissionSuccess(result.data);             
                }
            }, null);
        }

        function loadTemplate() {
            const host = $.ube.host;
            const templateURL = options.template || (host + "/form/" + name);
            $.get(templateURL)
                .then(res => {
                    container.html(res.template);
                    promoCampaignName = res.properties && res.properties.promoCampaignName || '';
                    sessionStorage.setItem('promoCampaignName', promoCampaignName)
                    initFace();
                })
        }

        function initFace() {
            container.find(".ube-visibility-show-for-method, .ube-visibility-show-for-megafonMethod, .ube-visibility-show-for-documentMethod").hide();
            container.find(".ube-visibility-show-for-faceMethod").show();
            gaPush("qr_age", "fr", promoCampaignName);
            getToken(initializeFaceCapture, undefined);
        }

        function initializeFaceCapture(_token, _tokenUrl) {
            $(".ube-face-error").hide();
            $(".ube-face-container").show();
            console.log("Request token", token);

            let token = _token;
            let tokenUrl = _tokenUrl;
            let fcm;
            
            function reloadToken() {
                getToken(function(newToken, newUrl) {
                    token = newToken;
                    tokenUrl = newUrl;
                });
            };

            var renderContainer = container.find(".ube-camera-container");
            var mode;
            var renderTarget = container.find(".ube-camera-render");
            var captureButton = container.find(".ube-camera-capture");
            var fallbackTarget = container.find(".ube-camera-fallback");
            if (renderTarget.data("init") || !token || !tokenUrl) return false;
            renderTarget.data("init", true);

            function sendBlobToServer(blob, callback, type, imageData) {
                toggleLoader(true);
                console.log("Sending blob to server", token);

                var eventType;
                if (type === 'auto') eventType = 'ACS Camera'
                else eventType = 'Photo Upload';
                
                gaPush("dl_qr_av_fr_process", `send - form - fr_${type}`);

                $.ajax({
                    url: tokenUrl,
                    data: blob,// the formData function is available in almost all new browsers.
                    type: "POST",
                    processData: false,
                    cache: false,
                    dataType: "json", // Change this according to your response from the server.
                    contentType: 'application/octet-stream',
                    crossDomain: true,
                    headers: {
                        "Authorization": "Bearer " + token,
                        "X-ACS-PICTURE-MODE": "stream"
                    },
                    error: function (err) {
                        toggleLoader(false);
                        console.error("Error from ACS API");
                        console.error(err);
                        logACSResultToELK(eventType, false, true, 'Network Error', imageData, b64Img, name);
                    },
                    success: function (data) {
                        toggleLoader(false);
                        console.log("Success from ACS API");
                        console.log(data);
                        if (data) {
                            console.log(data.status_code);
                            console.log(data.error_message);
                            console.log(data.error_code);
                            const errorMessage = data.error_message;
                            if (data.status_code === "SUCCESS" && !window.bad) {
                                const label = tryCount === 1 ? 'first' : 'second';
                                gaPush("dl_qr_av_fr_success", `form - fr_${type} - ${label}`);
                                logACSResultToELK(eventType, true, false, null, imageData, b64Img, name, token);
                                if (callback) callback();
                                onSuccess(token);
                            }
                            else if (data.next_validation_type === "DOCUMENT" || window.bad) {
                                if (callback) callback();
                                onError("limit", data);

                                let label;
                                if (errorMessage === "Technical Error") label = 'technical'
                                else if (tryCount === 1) label = 'first'
                                else label = 'second';
                                if (!data.error_message.includes('Yoti does not recognize')) {
                                    let label;
                                    if (errorMessage === "Technical Error") label = 'technical'
                                    else if (tryCount === 1) label = 'first'
                                    else label = 'second';
                                }

                                let detailMessage;

                                if (data.errors_detail) {
                                    detailMessage = data.errors_detail && data.errors_detail[0] && data.errors_detail[0].code
                                } else if (data.error_message) {
                                    detailMessage = data.error_message
                                } else {
                                    detailMessage = "Technical Error"
                                }
                                logACSResultToELK(eventType, false, false, detailMessage, imageData, b64Img, name);

                                if (data.errors_detail) {
                                    const errorMessage = data.errors_detail[0] && data.errors_detail[0].code || "Technical Error";
                                    let label;
                                    if (errorMessage === "Technical Error") label = 'technical'
                                    else if (tryCount === 1) label = 'first'
                                    else label = 'second';
                                    gaPush("dl_qr_av_fr_fail", `form - fr_${type} - ${label} - reason: ${errorMessage}`);
                                    logACSResultToELK(eventType, false, false, errorMessage, imageData, b64Img, name);
                                } else gaPush("dl_qr_av_fr_fail", `form - fr_${type} - ${label} - reason: ${errorMessage}`);
                            }
                            else if (data.error_message) {
                                console.log(data.error_message);
                                if (!data.error_message.includes('Yoti does not recognize')) {
                                    let label;
                                    if (errorMessage === "Technical Error") label = 'technical'
                                    else if (tryCount === 1) label = 'first'
                                    else label = 'second';
                                    gaPush("dl_qr_av_fr_fail", `form - fr_${type} - ${label} - reason: ${errorMessage}`);
                                }
                                let errorText = "Ой! Похоже, что вы выглядите слишком молодо.<br><br> <small>Возможно вы не выполнили одно из условий. В любом случае стоит попробовать еще раз, чтобы изображение получилось более чётким, не засвеченным и не слишком затемненным.</small>";
                                if (options.debug && $.ube.host === "https://ube-test.pmsm.org.ru") {
                                    const photoFileName = "photo_in_base_64.txt";
                                    const responseFileName = "face_reco_response.txt";
                                    const fileContent = b64Img;
                                    const photoFile = new Blob([fileContent], {type: 'text/plain'});
                                    const responseFile = new Blob([JSON.stringify(data)], {type: 'text/plain'});

                                    window.URL = window.URL || window.webkitURL;

                                    container.before(`<p>Error message: ${data.error_message}</p>`);
                                    if (data.errors_detail && data.errors_detail[0] && data.errors_detail[0].code)
                                        container.before(`<p>Error code reason: ${data.errors_detail[0].code}</p>`);
                                    container.before('<a class="ube-error-link btn btn-primary mb-3">Download uploaded photo in base64</a>');
                                    container.before('<a class="ube-error-link-2 btn btn-primary mb-3">Download response</a>');

                                    $('.ube-error-link')
                                        .attr('href', window.URL.createObjectURL(photoFile))
                                        .attr('download', photoFileName)
                                    $('.ube-error-link-2')
                                        .attr('href', window.URL.createObjectURL(responseFile))
                                        .attr('download', responseFileName)
                                }

                                let detailMessage;

                                if (data.errors_detail) {
                                    detailMessage = data.errors_detail && data.errors_detail[0] && data.errors_detail[0].code
                                } else if (data.error_message) {
                                    detailMessage = data.error_message
                                } else {
                                    detailMessage = "Technical Error"
                                }
                                logACSResultToELK(eventType, false, false, detailMessage, imageData, b64Img, name);
                                if (data.errors_detail) {
                                    const { code } = data.errors_detail[0];

                                    if (!!faceErrors[code]) {
                                        const { title, subtitle, text } = faceErrors[code];
                                        errorText = `<h4>${title}</h4><br><p>${subtitle}</p><small>${text}</small>`;
                                    }
                                    const errorMessage = code || "Technical Error"

                                    let label;
                                    if (errorMessage === "Technical Error") label = 'technical'
                                    else if (tryCount === 1) label = 'first'
                                    else label = 'second';
                                    gaPush("dl_qr_av_fr_fail", `form - fr_${type} - ${label} - reason: ${errorMessage}`);
                                }
                                if (tryCount < 2) {
                                    tryCount++;
                                    fcm.reload();
                                    if (options.acsOptions && options.acsOptions.debugMode && data.errors_detail) {
                                        $('body').append('<p class="server-error-message"></p>')
                                        $('.server-error-message').html(JSON.stringify(data.errors_detail));
                                    }
                                    showPopup("Ой! Похоже, что вы выглядите слишком молодо.<br><br> <small>Возможно вы не выполнили одно из условий. В любом случае стоит попробовать еще раз, чтобы изображение получилось более чётким, не засвеченным и не слишком затемненным.</small>");
                                } else {
                                    if (callback) callback();
                                    onError("limit", data);
                                }
                            }
                        }
                    }
                });
            }

            function renderCameraCapture() {                
                if(isNoCameraStream) return renderFileUpload();
                fallbackTarget.hide();
                mode = "stream";
                renderTarget.show();
                renderContainer.addClass("ube-camera-option-capture").removeClass("ube-camera-option-upload");

                    $('.ube-camera-capture').hide();

                    var onFaceSuccess = ({ img }) => {
                        console.log("Image data:");
                        b64Img = img;
                        console.log(img);
                        if (options.acsOptions && options.acsOptions.debugMode) {
                            if (!$('#result-image').length) {
                                $('.container').append('<textarea id="result-image"></textarea>');
                            }
                            $('#result-image').text(img);
                        }
                        var blob = imageDataToBlob(img);
                        console.log("Blob:");
                        console.log(blob);

                        var dummyForm = $("<form style='position: absolute;top:-2000px;left:-2000px;display: block;'>" +
                            "<input type=\"text\" id=\"filename\" name=\"filename\" />" +
                            "</form>");
                        dummyForm.appendTo("body");
                        var formDataToUpload = new FormData(dummyForm[0]);
                        formDataToUpload.append("image", blob);

                        var dummyImg = new Image();

                        dummyImg.onload = function() {
                            const imageData = {
                                width: dummyImg.width,
                                height: dummyImg.height,
                                sizeKB: getImageSize(img).toFixed(3)
                            }

                            const aggregatedImageData = {
                                initial: imageData,
                                resized: imageData
                            }

                            console.log("Sending ACS API request");
                            sendBlobToServer(blob, stopVideoCapture, 'auto', aggregatedImageData);
                        }
                        dummyImg.src = img;
                    };
                    var onFaceError = err => {
                        const errLabel = err.message || err || 'Unknown error';
                        console.log('Face capture error:', errLabel);
                        gaPush("dl_qr_av_fr_process", `start - form - fr_upload - reason: ${errLabel}`);
                        renderFileUpload();
                    };
                    var onReadyForCaptureFace = () => {
                        gaPush("dl_qr_av_fr_process", "start - form - fr_auto");
                        // YOTI PERFORMANCE MEASURE
                        if (options.acsOptions && options.acsOptions.performanceMode) {
                            var acsInit = window.performance.now();
                            var initTime = (acsInit - acsStart).toFixed(2);
                            alert('Init time: '+ initTime + ' ms');
                        }
                        
                        //! YOTI PERFORMANCE MEASURE
                    }
                    var props = {
                        faceCaptureAssetsRootUrl: $.ube.host + '/js/plugin/',
                        onSuccess: onFaceSuccess,
                        onError: onFaceError,
                        language: 'ru',
                        captureMethod: 'auto',
                        onReadyForCapture: onReadyForCaptureFace,
                        manualCaptureFallback: false
                    };
                    
                    // YOTI PERFORMANCE MEASURE
                    if (options.acsOptions && options.acsOptions.performanceMode) {
                        var acsStart = window.performance.now();
                    }

                    //! YOTI PERFORMANCE MEASURE

                    fcm = Yoti.FaceCaptureModule.render(props, renderTarget[0]);

                    var stopVideoCapture = function() {
                        console.log("stopVideoCapture()");
                        fcm.unmount();
                    };

                    form.on("stopVideoCapture", function () {
                        console.log("Handling stop function");
                        stopVideoCapture();
                    });
            }

            function renderFileUpload() {
                mode = "upload";
                renderContainer.removeClass("ube-camera-option-capture").addClass("ube-camera-option-upload");
                fallbackTarget.show();
                renderTarget.hide();
                $('.ube-camera-capture').show();
                var dummyFile = $("<input type=\"file\" accept=\"image/*\" capture=\"user\" style='width:0;height:0;position:absolute;'/>").appendTo("body");
                var canvas = $("<canvas class='ube-dummy-image' style='visibility:hidden;display:block;position: absolute;top:-5000px;left:-5000px'></canvas>").appendTo("body")[0];
                var maxWidth = 2000;
                var maxHeight = 2000;
                var maxPixels = 2000000;

                dummyFile.off("change").change(function () {
                    console.log("File field changed");
                    var reader = new FileReader();

                    reader.addEventListener("load", function () {
                        var initialImage = this.result;
                        console.log("Image data:");
                        console.log(initialImage);
                        b64Img = initialImage;
                        
                        var dummyImg = new Image();
                        var resizedImage;
                        dummyImg.onload = function() {
                            var imageFileSizeKB = getImageSize(initialImage).toFixed(3);
                            var ctx = canvas.getContext("2d");
                            const initialImageData = {
                                width: dummyImg.width,
                                height: dummyImg.height,
                                sizeKB: imageFileSizeKB
                            };
                            
                            const isImageValid = resizeImage(dummyImg, maxWidth, maxHeight, maxPixels);

                            canvas.width = dummyImg.width;
                            canvas.height = dummyImg.height;
                            ctx.drawImage(dummyImg, 0, 0, dummyImg.width, dummyImg.height);

                            resizedImage = canvas.toDataURL('image/jpeg', 0.9);
                            
                            dummyFile.val("");
                            dummyFile.wrap('<form>').closest('form').get(0).reset();
                            dummyFile.unwrap();

                            const resizedImageData = {
                                width: dummyImg.width,
                                height: dummyImg.height,
                                sizeKB: getImageSize(resizedImage).toFixed(3)
                            }

                            const aggregatedImageData = {
                                initial: initialImageData,
                                resized: isImageValid ? initialImageData : resizedImageData
                            }

                            const blob = imageDataToBlob(isImageValid ? initialImage : resizedImage);
                            sendBlobToServer(blob, undefined, 'upload', aggregatedImageData);
                        }
                        dummyImg.src = initialImage;
                        
                    }, false);

                    reader.readAsDataURL(this.files[0]);
                });

                captureButton.add(".ube-camera-option-upload").off("click").click(function (e) {
                    console.log("Capture upload clicked");
                    e.preventDefault();
                    dummyFile.click();
                    return false;
                });
            }

            renderCameraCapture();
        }

        const {isTemplatePreloaded, onSubmissionSuccess} = options;

        if(isTemplatePreloaded) {
            initFace()
        } else {
            loadTemplate()
        }

        Impression.runImpression(name);
    };
})();
