const faceErrors = {
    AntispoofingFake: {
        title: 'Хм...',
        subtitle: 'Похоже, ты используешь фото другого человека.',
        text: 'Пожалуйста, попробуй выбрать другой снимок себя или включи камеру.',
    },
    AntispoofingUndetermined: {
        title: 'Сожалеем!',
        subtitle: 'Нам не удалось убедиться, что это действительно ты.',
        text: 'Пожалуйста, попробуй выбрать другой снимок себя или включи камеру.',
    },
    FaceBoxTooSmall: {
        title: 'Упс...',
        subtitle: 'Лицо на представленном изображении слишком маленькое.',
        text: 'Пожалуйста, попробуй снова.',
    },
    YotiServerError: {
        title: 'Хм...',
        subtitle: 'Наш сервис временно недоступен.',
        text: 'Пожалуйста, попробуй снова.',
    },
    default: {
        title: 'Хм...',
        subtitle: 'Кажется, ты выглядишь слишком молодо.',
        text: 'Пожалуйста, попробуй снова.',
    }
}

const errorMap = {
    'IMAGE_SIZE_TOO_SMALL': 'Image size too small',
    'IMAGE_GRAYSCALE_NOT_SUPPORTED': 'Image grayscale not supported',
    'MAX_HEIGHT': 'Max height',
    'MAX_WIDTH': 'Max width',
    'INSUFFICIENT_AREA_AROUND_THE_FACE': 'Insufficient area around the face',
    'IMAGE_TOO_DARK': 'Image too dark',
    'IMAGE_TOO_BRIGHT': 'Image too bright',
    'FACE_TO_IMAGE_RATIO_TOO_HIGH': 'Face to image ratio too high',
    'FACE_TO_IMAGE_RATIO_TOO_LOW': 'Face to image ratio too low',
    'MULTIPLE_FACES': 'Multiple faces',
    'FaceBoxTooSmall': 'FaceBox too small',
    'AntispoofingFake': 'Antispoofing fake',
    'AntispoofingUndetermined': 'Antispoofing undetermined',
    'YotiServerError': 'Yoti server error'
}

export { faceErrors, errorMap }
