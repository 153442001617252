const getQueryParameter = (query, sParam) => {
    const sPageURL = query.includes('?') ? decodeURIComponent(query.substring(1)) : decodeURIComponent(query);
    const sURLVariables = sPageURL.split('&');
    let sParameterName, i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split(/=(.*)/s);

        if (sParameterName[0] === sParam) 
            return sParameterName[1] || true
    }
};

const getQueryString = url => url.split('?')[1] || '';

const getParameters = (...parameters) => {
    const searchParams = new URLSearchParams(window.location.search);

    if(parameters.length === 1)
        return searchParams.get(parameters[0]);

    return parameters.map((parameter) => searchParams.get(parameter));
}

export { getQueryParameter, getQueryString, getParameters }
