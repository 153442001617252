import $ from 'jquery';


const dotBirthDate = birthDate =>
    (birthDate || "")
        .replace(/^.*(\d{2})[^0-9]*(\d{2})[^0-9]*(\d{4}).*$/, "$1.$2.$3")
        .replace(/^.*(\d{4})[^0-9]*(\d{2})[^0-9]*(\d{2}).*$/, "$3.$2.$1");

class Field {

    #factory;
    #key;

    #autoAddToDOM = ["locationId", "entity", "socialId", "socialKey", "phoneCodeSentAt", "phoneCodeSent", "emailCodeSentAt",
        "emailCodeSent", "phoneValidatedValue", "emailValidatedValue", "method", "profileId", "token", "tokenUrl", "externalId",
        "utmCampaign", "utmContent", "utmSource", "utmMedia", "isPhoneCross", "isEmailCross", "popup",
        "mobileTelephoneVerifiedCode", "emailVerifiedCode", "phoneVerifiedLabel", "emailVerifiedLabel",
        "isEmailChangedOneMonth", "isPhoneChangedOneMonth", "dteContactId"];
    #trimFields = ["firstName", "lastName", "email", "middleName"];

    constructor(factory, key) {
        this.#factory = factory;
        this.#key = key;
        this.getDOMField = this.getDOMField.bind(this);
        this.createFieldIfAbsent = this.createFieldIfAbsent.bind(this);
        this.setDOMFieldLabel = this.setDOMFieldLabel.bind(this);
        this.setDOMFieldValue = this.setDOMFieldValue.bind(this);
        this.getDOMFieldValue = this.getDOMFieldValue.bind(this);
    }

    get container() {
        return this.#factory.container;
    }

    get key() {
        return this.#key;
    }

    get component() {
        return this.#factory.component(this.key);
    }

    get typ() {
        return this.component.type;
    }

    createFieldIfAbsent() {
        const key = this.key;
        if (this.#autoAddToDOM.indexOf(key) >= 0 && this.getDOMField().length === 0)
            this.container.find("form").append('<input type="hidden" name="' + key + '"/>');
    }

    getDOMField(filter) {
        const container = this.container;
        const key = this.key;
        const typ = this.typ;

        let field;
        if (filter && filter.length > 0)
            field = container.find("[name='" + key + "']").filter(filter);
        else if (typ === 'radio') {
            field = container.find("[name='" + key + "']:checked");
            if (field.length === 0) field = container.find("[name='" + key + "']");
        } else
            field = container.find("[name='" + key + "']");
        return field;
    }

    setDOMFieldLabel(value) {

        const key = this.key;

        let label = value;

        if(key === "gender") {
            switch(value) {
                case "male": label = "Мужской"; break;
                case "female": label = "Женский"; break;
                default: label = ""; break;
            }
        }
        else if(key === "phone" || key === "phoneValidatedValue") {
            if(("" + label).length === 10) {
                label = (label + "").replace(/^(\d{3})(\d{3})(\d{2})(\d{2})$/,"+7 ($1) $2-$3-$4")
            }
        }
        else if(key === "birthDate") {
            label = dotBirthDate(label);
        }

        this.container.find(".ube-value-label-" + key).html(label);


    }

    setDOMFieldValue(value) {
        const container = this.container;
        const key = this.key;
        const typ = this.typ;
        const getField = this.getDOMField;
        const options = this.#factory.options;
        const createFieldIfAbsent = this.createFieldIfAbsent;
        
        const common = function (key, value) {
            if (typ === "radio") {
                var fieldIsRadio = getField("[type=radio]").length > 0;
                if (fieldIsRadio) {
                    if (value) getField("[value='" + value + "']").prop("checked", true);
                    else getField().prop("checked", false);
                } else {
                    getField().val(value);
                }
            }
            else if (typ === "checkbox") {
                getField().prop("checked", value);
            }
            else if (["locationId", "cigaretteType", "cigaretteTypeExt", "cigaretteBrand", "cigaretteBrandExt"].indexOf(key) > -1) {
                getField().attr('data-value', value);
            }
            else {
                createFieldIfAbsent();
                getField().val(value);
            }
        };
        if (options.setFieldValue) options.setFieldValue(key, value, common);
        else common(key, value);

        const selectorToSetValue = container.find(".ube-value-" + key);

        if(selectorToSetValue.is("input[type=checkbox]"))
            selectorToSetValue.prop("checked", value);
        else
            selectorToSetValue.html(value);

        this.setDOMFieldLabel(value);
    }

    /**
     *
     * @param defaultValue - value if DOM field is absent
     * @returns {any}
     */
    getDOMFieldValue(defaultValue) {
        const typ = this.typ;
        const field = this.getDOMField();
        if (!field || field.length === 0)
            return defaultValue;
        else if (typ === "checkbox")
            return field.is(":checked");
        else if (typ === "select" && this.component.dataSrc === "url")
            return field.attr("data-value");
        else if (typ === "phoneNumber")
            return field.val() ? field.val().replace(/[^0-9]/g, "").replace(/^([78])([0-9]{10}).*$/, "$2") : undefined;
        else if (typ === "file")
            return field.data("base64");
        else {
            let value = field.attr("data-value") || field.val();
            if(value && this.#trimFields.includes(this.key)) value = value.trim();
            return value;
        }
    }
}

export { Field as default }