import $ from 'jquery';
import {ubeHostFallBack} from '../util/fallback';
import { isNoCameraStream } from '../util/device';
import { toggleLoader } from '../util/toggleLoader';

(function () {

  const sendRequest = function(apiUrl, type, data, callback) {
    toggleLoader(true);
    return $.ajax(apiUrl, {
      type,
      data,
      contentType: "application/json",
    })
    .done(response => {
      toggleLoader(false);
      console.log('UBE :: Successful request to ' + apiUrl + ' with response:', response);
      $('#qr-reader').remove();
      if (callback) callback(response);
    })
    .fail(error => {
      toggleLoader(false);
      console.log('UBE :: Request to ' + apiUrl + ' failed with error:', error);         
    })
  }

  $.fn.ubeCamera = function (name, options) {
    var container = $(this).first();
    var form = container.find("form");
    const { getPhotoBase64 } = options;

    ubeHostFallBack();

    function initCamera() {
      container.find(".ube-visibility-show-for-method, .ube-visibility-show-for-megafonMethod, .ube-visibility-show-for-documentMethod, .ube-visibility-show-for-faceMethod").hide();
      container.find(".ube-visibility-show-for-camera").show();

      initializeCameraCapture();
    }

    function initializeCameraCapture() {
      $(".ube-camera-error").hide();
      $(".ube-camera-container").show();

      var renderContainer = container.find(".ube-camera-container");
      var mode;
      var renderTarget = container.find(".ube-camera-render");
      var captureButton = container.find(".ube-camera-capture");
      var fallbackTarget = container.find(".ube-camera-fallback");
      if (renderTarget.data("init")) return false;
      renderTarget.data("init", true);

      function renderCameraCapture() {
        if(isNoCameraStream) return renderFileUpload();
        fallbackTarget.hide();
        mode = "stream";
        renderTarget.html("<video></video>").show();
        renderContainer.addClass("ube-camera-option-capture").removeClass("ube-camera-option-upload");
        var video = renderTarget.find("video")[0];

        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {

          video.setAttribute('autoplay', '');
          video.setAttribute('muted', '');
          video.setAttribute('playsinline', '');

          var constraints = {
            audio: false,
            video: {
              facingMode: 'environment'
            }
          };

          var stopVideoCapture = function() {
            console.log("stopVideoCapture()");
            video.pause();
            video.src = "";
            if (video.srcObject) {
              video.srcObject.getTracks().forEach(function(track) {track.stop()});
            }
            renderTarget.html("");
            renderTarget.data("init", false);
          };

          form.on("stopVideoCapture", function () {
            console.log("Handling stop function");
            stopVideoCapture();
          });

          navigator.mediaDevices.getUserMedia(constraints)
            .then(function (stream) {
              video.srcObject = stream;
              console.log("Video capture init success");

              captureButton.off("click").click(function (e) {
                e.preventDefault();
                var canvas = $("<canvas style='visibility:hidden;display:block;position: absolute;top:-2000px;left:-2000px'></canvas>").appendTo("body")[0];

                console.log("Capturing video");
                console.log(canvas.width);
                console.log(canvas.height);

                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                canvas.getContext('2d').drawImage(video, 0, 0);
                // Other browsers will fall back to image/png

                var imageData = canvas.toDataURL('image/png');

                console.log("Image data:");
                console.log(imageData);

                if(getPhotoBase64) {
                  getPhotoBase64(imageData)
                }

                return false;
              });

            })
            .catch(function (e) {
              console.log("Capture video error 1");
              renderFileUpload();
            });
        } else {
          console.log("Capture video error 2");
          renderFileUpload();
        }

      }

      function renderFileUpload() {
        mode = "upload";
        renderContainer.removeClass("ube-camera-option-capture").addClass("ube-camera-option-upload");
        fallbackTarget.show();
        renderTarget.hide();

        var dummyFile = $("<input type=\"file\" accept=\"image/*\" capture=\"user\" style='width:0;height:0;position:absolute;'/>").appendTo("body");

        dummyFile.off("change").change(function () {
          console.log("File field changed");
          var reader = new FileReader();

          reader.addEventListener("load", function () {
            var imageData = this.result;
            console.log("Image data:");
            console.log(imageData);

            if(getPhotoBase64) {
              getPhotoBase64(imageData)
            }

            dummyFile.val("")
            dummyFile.wrap('<form>').closest('form').get(0).reset();
            dummyFile.unwrap();
          }, false);

          reader.readAsDataURL(this.files[0]);
        });

        captureButton.add(".ube-camera-option-upload").off("click").click(function (e) {
          console.log("Capture upload clicked");
          e.preventDefault();
          dummyFile.click();
          return false;
        });
      }

      renderCameraCapture();
    }

    initCamera();
  };

  $.fn.ubeCapture = function(entity, sessionKey, options, callback) {
    console.log(options);
    const { fps, boxSize, type = 'recognition' } = options;
    const container = $(this);

    container.html('<div id="qr-reader"></div>');

    const html5QrCode = new Html5Qrcode("qr-reader", { formatsToSupport: [ Html5QrcodeSupportedFormats.DATA_MATRIX ] });
    const qrCodeSuccessCallback = decodedText => {
        console.log(decodedText);
        html5QrCode.stop();
        $('.ube-capture-canvas').html('');
        $('.ube-capture-result').html('Результат сканирования:<br>' + decodedText);
        let data;
        switch (type) {
          case 'bind':
            data = JSON.stringify({
              sessionKey,
              serial: decodedText,
              data: {
                entity
              }
            });
            sendRequest(`${$.ube.host}/api/session/device/bind`, 'POST', data, callback);
            break;
          case 'recognition': 
            data = JSON.stringify({
              decodedMatrix: decodedText,
            });
            //TODO получить URL API проверки DataMatrix
            if (callback) callback({
              status: 'success',
              label: 'Распознавание Data Matrix',
              message: 'Успешно распознано',
              decodedText
            });
            break;
        }
    };
    const config = { fps, qrbox: boxSize };
    
    html5QrCode.start({ 
      facingMode: 'environment',
    }, config, qrCodeSuccessCallback);
  }
})();
