import $ from 'jquery';
import {ubeHostFallBack} from '../util/fallback';

(function () {

    const limit = 10;

    const months = ["Января", "Февраля", "Марта", "Апреля", "Мая", "Июня", "Июля", "Августа", "Сентября", "Октября", "Ноября", "Декабря"];

    function loadTemplate() {
        return $.get($.ube.host + "/template/loyalty-history");
    }

    function loadData(sessionKey) {
        return $.get($.ube.host + "/api/session/get?attributes=1&sessionKey=" + sessionKey);
    }

    function displayLoyalty(container, templateString, data) {
        $(".loyalty-history").remove();

        const template = $(templateString);
        const holder = template.find(".loyalty-history_holder");
        const entry = template.find(".loyalty-history_entry").clone();

        holder.empty();

        const dataItemToDom = item => {
            const entryCopy = entry.clone();

            const points = parseInt(item.points) || 0;
            let pointText = "Баллов";
            if((points % 10) === 1) pointText = "Балл";
            else if((points % 10) < 5 && (points % 10) > 1) pointText = "Балла";

            if (item.deactivated && item.deactivatedText && !item.active) {
                entryCopy.find(".loyalty-history_label").text("У вас списаны баллы");
                entryCopy.find(".loyalty-history_date").text(item.deactivated);
                entryCopy.find(".loyalty-history_description").text(item.deactivatedText);
                entryCopy.find(".loyalty-history_points").text("- "+points+" "+pointText);
                holder.append(entryCopy);
            } else if(item.created && item.text) {
                entryCopy.find(".loyalty-history_label").text("Вам начислены баллы");
                entryCopy.find(".loyalty-history_date").text(item.created);
                entryCopy.find(".loyalty-history_description").text(item.text);
                entryCopy.find(".loyalty-history_points").text("+ "+points+" "+pointText);
                holder.append(entryCopy);
            }
        };

        const showAllButton = template.find(".loyalty-history_button a");


        function showAllFun() {
            showAllButton.hide();
            data.slice(limit).forEach(dataItemToDom);
        }

        showAllButton.click(e => {
            e.preventDefault();
            setTimeout(showAllFun, 300);
            return false;
        });

        if(data.length > 0) {

            data.slice(0, limit).forEach(dataItemToDom);

            if(data.length > limit) showAllButton.show();
            else showAllButton.hide();
            template.find(".loyalty-history_empty").hide();
        } else {
            showAllButton.hide();
            template.find(".loyalty-history_empty").show();
        }

        container.html(template);
    }

    function formatDate(dateString) {
        if(!dateString) return dateString;
        if (typeof dateString === 'string' || dateString instanceof String) {
            dateString=formatDateSort(dateString);
            const dateSplit = dateString.split("-");
            const year = parseInt(dateSplit[0]);
            const month = months[parseInt(dateSplit[1]) - 1];
            const day = parseInt(dateSplit[2]);

            const currentYear = new Date().getFullYear();
            if(year === currentYear) return `${day} ${month}`;
            else return `${day} ${month} ${year}`;
        }
        return dateString;
    }

    function formatDateSort(dateString) {
        if(!dateString) return dateString;
        if (typeof dateString === 'string' || dateString instanceof String) {
            if(dateString.length === 10) dateString = dateString + "T23:59:59.999Z";
        }
        return dateString;
    }

    function historyFromData(data) {
        if(data && data.attributes) {
            const loyaltyHistory = data.attributes.loyaltyHistory || [];

            const existingKeys = [];
            const filteredLoyaltyHistory = [];

            loyaltyHistory.sort((a, b) => {
                const dateA = formatDateSort(a.deactivated || a.created);
                const dateB = formatDateSort(b.deactivated || b.created);
                if(dateA < dateB) return 1;
                else if(dateA === dateB) {
                    if(a.deactivated && !b.deactivated) return -1;
                    else if(!a.deactivated && b.deactivated) return 1;
                    else return 0;
                }
                else return -1;
            }).forEach(item => {
                const isActive = [1, "1", true, "true", "True", "TRUE", "Yes", "YES", "TRUE"].indexOf(item.active) > 0;
                const key = item.id + (isActive ? "1" : "0");

                item.active = isActive;
                item.created = formatDate(item.created);
                item.deactivated = formatDate(item.deactivated);
                item.points = parseInt(item.points);

                if(existingKeys.indexOf(key) === -1) {
                    existingKeys.push(key);
                    filteredLoyaltyHistory.push(item);
                }
            });

            return filteredLoyaltyHistory;

        } else return [];
    }

    function displayError(container, e) {
        console.error(e);
        container.html("<p>Ошибка при получении данных</p>")
    }

    $.fn.ubeLoyalty = function (sessionKey) {

        if (!sessionKey || sessionKey === "") throw new Error("loyalty requires sessionKey as parameter");

        const container = $(this).first();

        ubeHostFallBack();

        let template;
        let data;

        loadTemplate().then(_template => {
            template = _template;
            if (data && template) displayLoyalty(container, template, data);
        }).catch(e => {
            displayError(container, e);
        });

        loadData(sessionKey).then(_data => {
            data = historyFromData(_data);
            if (data && template) displayLoyalty(container, template, data);
        }).catch(e => {
            displayError(container, e);
        });
    };
})();
