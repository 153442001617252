/**
 *
 * @param {object} options
 * @param {string} path
 * @param {boolean} isLeadForm
 * @returns {string}
 */
function actionPathReg(options, path, isLeadForm) {
    let action_path;
    action_path = isLeadForm ? "/api/lead/" + path + "/submission" : "/esb/" + path + "/submission";
    if(options.actionPathReg) {
        if (typeof options.actionPathReg === 'function') {
            action_path = options.actionPathReg(path);
        }
        else {
            throw new Error('options.actionPathReg must be a function(path) {return string;}');
        }
    }
    return action_path;
}

export {actionPathReg};