export class Context {
    constructor(host, path) {
        this.host = host;
        this.path = path;
    }
}

export class Widget {
    constructor() {

    }

    /**
     * Check if widget should launch on this context
     * @param context {Context} widget context
     * @returns {boolean}
     */
    isDefinedAt(context) {}

    /**
     * Launch widget
     * @param context {Context} widget context
     */
    launch(context) {

    }
}