import { cookies, ubeCookie } from "../../../util/cookie";
import { Block } from "./block";

export class Registration extends Block {
    run(key, state, cb) {
        const options = this.options;
        const target = this.initContainers('login', 'register');
        const isIDXEnabled = !state.faceAV;

        target.show().ube(this.name, {
            isTemplatePreloaded: false,
            loadFormDataFromSession: true,
            sessionKey: state.sessionKey,
            data: {
                ...state.user
            },
            onFormLoad: function () {
                if (isIDXEnabled) {
                    $(".ube-av1").hide();
                } else {
                    $(".ube-av0").hide();
                }
                options.onFormLoad && options.onFormLoad(key);
            },
            onSubmissionSuccess: function (submissionId, userId, result) {
                const response = {
                    event: result.event,
                    sessionKey: result.data.sessionKey,
                    userId
                };
                const block = { regStatus: '' };
                const state = { 
                    sessionKey: result.data.sessionKey,
                    result
                 };

                state.originalEvent = 'registration';
                ubeCookie(cookies.UBE_WIDGET_REGISTER, 'Y');
    
                switch (result.event) {
                    case "alreadyVerified":
                        console.log("UBE Register AV1 result", response);

                        block.regStatus = 'alreadyVerified';
                        state.av = 1;
                        state.avStatus = 1;

                        cb(block, state);
                        break;
                    case "registered":
                        console.log("UBE Register result", response);
                        $('#popup-content').hide();
                        $('#registration-content').hide();

                        block.regStatus = 'registered';
                        state.av = 0;
                        state.avStatus = 0;

                        cb(block, state);
                        break;
                    case "2optin":
                        block.regStatus = 'optin';
                        
                        cb(block, state);
                        break;
                }
            }
        });
    }

    close(state) {
       if(!state.withOptin) $('.ube-form-register').hide();
    }
}
