import $ from 'jquery';

/**
 *
 * @param {function} onStop
 * @param {integer} secondsCount
 * @param {boolean} isSecond
 * @return {{stop: stop, start: start}}
 */
$.fn.ubeTimer = function (onStop, secondsCount, isSecond) {
    var target = $(this);

    var timer;
    var timeLeft;

    function stop() {
        if (timer) {
            clearInterval(timer);
            clearTimeout(timer);
            timer = undefined;
        }
    }
    
    function iterateOneSecond() {

        timeLeft--;
        var timeText = " секунд";
        var mod = timeLeft % 10;
        var mod100 = timeLeft % 100;
        if (mod100 < 10 || mod100 > 20) {
            if (mod === 1) timeText = isSecond ? " секунду" : " секунда";
            else if (mod > 1 && mod < 5) timeText = " секунды";
        }
        target.text(timeLeft + timeText);
        if (timeLeft <= 0) {
            stop();
            if(onStop) onStop();
        }
    }

    return {
        start: function() {
            stop();
            timeLeft = secondsCount || 60;
            iterateOneSecond();
            timer = setInterval(iterateOneSecond, 1000);
        },
        stop: stop
    };
};