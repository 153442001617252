import $ from 'jquery';
import {ubeHostFallBack} from '../util/fallback';
import {ubeCookie, cookies} from '../util/cookie';
import {gaPush} from "../util/ga";
import { getQueryParameter, getQueryString } from '../util/url';

(function () {

    const hostname = window.location.hostname;
    const pathname = window.location.pathname;

    const defaultAllocationCode = "CTBPL";

    const defaultUtmMedium = "web";

    const utmSources = {
        scanpack: "scanpack",
        premiumOne: "premium-one"
    };

    const UBE_PROD_HOST = "ube.pmsm.org.ru";

    const domainToCode = {
        "parliament.ru": "CTBPL",
        "marlboro.ru": "CTBML",
        "mrphilipmorris.ru": "CTBPM",
        "bondstreet.ru": "CTBBS",
        "lmlab.ru": "CTBLM",
        "nextlook.ru": "CTBNX",
        "mychesterfield.ru": "CTBCE"
    };

    /**
     * Finds domain by allocation code
     * @param {string} allocationCode
     * @return {string}
     */
    function domainByCode(allocationCode) {
        return Object.keys(domainToCode).find(key => domainToCode[key] === allocationCode);
    }

    function toggleLoader(visible) {
        console.log("UBE Toggle loader " + visible);
        if ($.ube && $.ube.toggleLoader) $.ube.toggleLoader(visible);
    }

    function showPopup(message) {
        if ($.ube && $.ube.showPopup)
            $.ube.showPopup(message);
        else {
            console.log(message);
            alert(message);
        }
    }

    /**
     * Generates and returns consumer link in callback
     * @param {string} sessionKey
     * @param {string} allocationCode - CTBBS, CTBML, etc
     * @param {function} callback
     * @param {number} [repeat=3]
     */
    function generateLinkByAllocation(sessionKey, allocationCode, callback, repeat) {
        const host = $.ube.host;

        toggleLoader(true);

        if(!repeat) repeat = 3;

        /**
         * Direct link if autologin link failed
         */
        function fallback() {
            const domain = domainByCode(allocationCode);
            if(!domain) {
                console.error("UBE Невозможно создать прямую ссылку на сайт");
                showPopup("Ошибка создания персональной ссылки");
            }
            else callback(`https://${domain}/?`);
        }

        function addProtocolPrefix(link) {
            return (link && !link.startsWith("http")) ? ("https://" + link) : link
        }

        if(!sessionKey || sessionKey === "") return fallback();

        $.ajax({
            url: host+"/api/session/createHash",
            headers: {"ube-session-key": sessionKey},
            data: {
                allocationCode: allocationCode
            },
            success: function (data, status, xhr) {
                toggleLoader(false);

                if(data.result !== true || !data.value) {
                    if(repeat > 1) {
                        generateLinkByAllocation(sessionKey, allocationCode, callback, repeat -1)
                    } else {
                        console.log("UBE :: Generate personal link error");
                        console.log(xhr, status, data);
                        return fallback();
                    }
                } else {
                    callback(addProtocolPrefix(data.value));
                }
            },
            error: function (xhr, resp, text) {
                if(repeat > 1) {
                    generateLinkByAllocation(sessionKey, allocationCode, callback, repeat -1)
                } else {
                    toggleLoader(false);
                    console.log("UBE :: Generate personal link error");
                    console.log(xhr, resp, text);
                    return fallback();
                }
            }
        });
    }

    /**
     * Retrieves utm source from domain
     * @return {string}
     */
    function utmSourceByURL () {
        if(hostname.startsWith("qr.")) return utmSources.scanpack;
        else if(hostname.endsWith("premium.one") && pathname.startsWith("/qr/")) return utmSources.scanpack;
        else if(hostname.endsWith("premium.one")) return utmSources.premiumOne;
        else return utmSources.scanpack;
    }

    /**
     * Retrieves utm campaign from path
     * @return {string}
     */
    function utmCampaignByURL () {
        if (hostname.startsWith("qr.")) return pathname;
        else if (hostname.endsWith("premium.one") && pathname.startsWith("/qr/")) return pathname.replace(/^\/qr\/([^\/]+)\/(.*[^\/])\/?\/?$/, "$2");
        else return pathname.replace(/^\/?(.*[^\/])\/?$/, "$1");
    }

    /**
     * Retrieves allocation code from domain
     * @return {string}
     */
    function allocationCodeByURL () {

        //Cases like qr.parliament.ru, qr.marlboro.ru
        let foundDomain = Object.keys(domainToCode).find(x => hostname.endsWith(x));

        //Cases like test.premium.one/qr/parliament/
        if(!foundDomain) foundDomain = Object.keys(domainToCode).find(x => pathname.includes(x.replace(".ru","")))

        if(foundDomain) return domainToCode[foundDomain];

        return defaultAllocationCode;
    }


    /**
    * Check if MBEP
    * @returns {boolean}
    */
    const isMBEPCheck = (link) => !!Object.keys(domainToCode).find(key => link.includes(key));


    /**
    * Check if QR
    * @returns {boolean}
    */
    const isQRCheck = hostname => hostname.includes("qr.");

    /**
    * Check if P1
    * @returns {boolean}
    */
    const isP1Check = hostname => hostname.includes("premium.one");

    /**
     * Переадресация потребителя на сгенерированную хеш ссылку
     * @param options
     */
    $.ubeLink = function (options) {
        let {allocationCode, sessionKey, utmSource, utmMedium, utmCampaign, utmContent, utmTerm, callback, redirect, formKey, external} = options || {};

        if(!allocationCode || allocationCode === "") allocationCode = allocationCodeByURL();
        if(!utmCampaign || utmCampaign === "") utmCampaign = utmCampaignByURL();
        if(!utmSource || utmSource === "") utmSource = utmSourceByURL();
        if(!utmMedium || utmMedium === "") utmMedium = defaultUtmMedium;
        if(!sessionKey || sessionKey === "") sessionKey = ubeCookie(cookies.UBE_WIDGET_SESSION_KEY) || ubeCookie(cookies.UBE_SESSION_KEY)
;
        ubeHostFallBack();

        const host = $.ube.host;
        let category, action, label;
        if (isP1Check(hostname)) {
            category = `dl_p1_to_${allocationCode.includes('IQ') ? 'iqos' : 'mbep'}`;
            action = `p1_${window.location.pathname} - to_${allocationCode.includes('IQ') ? 'iqos' : 'mbep'}_${redirect}`;
        } else if (isQRCheck(hostname)) {
            category = 'dl_qr_to_mbep';
            action = `qr_${hostname}_${window.location.pathname} - to_mbep_${domainByCode(allocationCode)}_${redirect}`;
        } else {
            category = 'UBE-Link';
            action = `UBE-Link-${allocationCode}`;
            label = allocationCode;
        }
        if(!options.external) gaPush(category, action, label);

        const domain = domainByCode(allocationCode);
        const linkIsMBEP = isMBEPCheck(hostname);
        if(!external && (linkIsMBEP || hostname.includes(domain))) {
            const mbepDomain = domain || hostname;
            let stageRedirect = '/api/to-brand/?brand='
            stageRedirect += !host.includes(UBE_PROD_HOST) ? window.location.host.split('-').slice(0, -1).join('-') + '.' + mbepDomain : mbepDomain;
            if (formKey) stageRedirect += '&formKey=' + formKey;
            if (redirect) stageRedirect = stageRedirect + '&page=' + encodeURIComponent(redirect);
            BX.ajax.runComponentAction('cc:ube.client.forms', 'authBySessionKey', {
                mode: 'class',
                data: {
                    sessionKey,
                }
            }).then(function () {
                window.location.href = window.location.origin + stageRedirect;
            }).catch(function (error) {
                console.log(error.message);
            });
        } else {
            generateLinkByAllocation(sessionKey, allocationCode, link => {
                let linkWithUtm = link + `&utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}`;

                if(utmContent && utmContent !== "") linkWithUtm += `&utm_content=${utmContent}`;
                if(utmTerm && utmTerm !== "") linkWithUtm += `&utm_term=${utmTerm}`;

                if(redirect && redirect !== "") linkWithUtm += `&redirect=${encodeURIComponent(redirect)}`;

                if(callback) callback(link, linkWithUtm);
                else if(external && external.link) {
                    const sourceQuery = getQueryString(link);
                    if (!sourceQuery) return console.error('UBE :: empty query string, check returning url');

                    const targetQuery = getQueryString(external.link);
                    console.log(sourceQuery);
                    const hash = getQueryParameter(sourceQuery, 'T');
                    window.location.href = `${external.link}${targetQuery ? '&' : '?'}${external.tokenParam}=${hash}`;
                } else window.location.href = linkWithUtm;
            });
        }


    };
})();
