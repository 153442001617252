function gaEvent (action, label, value) {
    //console.log("GA Event: "+action+" "+label);
    try {
        ga('send', 'event', 'UBE', action, label, value);
    }
    catch (e) {
        //console.error("UBE :: GA Is not configured");
    }
}

function preparePayload(category, action, label) {
    var categories = ['Login', 'PersonalCabinet', 'Registration', 'PhoneVerification'];
    var pageCategories = ['qr_age', 'qr_phone', 'qr_reg'];
    var actions = ['Start'];
    var nevent = (categories.includes(category) && actions.includes(action)) ? 'nevent' : false;
    var pageViewEvent = pageCategories.includes(category) ? 'dl-pageview' : false;
    var result = {
        'event': pageViewEvent || nevent || 'aevent',
        'interactionType': 'False'
    }
    if(nevent) {
        result = Object.assign(result, {
            'ncategory': category || "UBE",
            'naction': action,
            'nlabel': label
        })
    } else if (pageViewEvent) {
        result = Object.assign(result, {
            'pageURL': window.location.href,
            'pageType': category,
            'pageParameters': action || '',
            'pageCampaign': label || ''
        })
    } else {
        result = Object.assign(result, {
            'ecategory': category || "UBE",
            'eaction': action,
            'elabel': label
        })
    }

    return result;
}

function gaPush (category, action, label) {
    var payload = preparePayload(category, action, label);

    try {
        dataLayer.push(payload);
        if(payload.event === 'aevent' && window.ymGoal) window.ymGoal(category, action, label, '');
    }
    catch(e) {
    }

    try {
        gaEvent(category, action);
    }
    catch (e) {
    }
}

function gaUserId (userId) {
    try {
        dataLayer.push({
            'UserID': userId,
            'userid': userId,
            'userId': userId
        });
    }
    catch (e) {}

    try {
        ga('set', 'userId', userId);
    }
    catch (e) {}

    try {
      window.gib.setAuthStatus(window.gib.IS_AUTHORIZED);
      window.gib.setLogin(userId);
    }
    catch (e) {}


}

const dataVariablesToGa = ["allocationCode", "ref", "partner"];
const pageVariablesToGa = ["event", "pageURL", "pageType", "pageParameters", "pageCampaign"];

/**
 *
 * @param {{}} data - object with variables
 */
function gaPushObject(data) {
    try {
        if(!data) return;
        const gaObject = {};
        dataVariablesToGa.forEach(key => {
            if(data[key]) gaObject["v" + key] = data[key];
        });
        pageVariablesToGa.forEach(key => {
            if(data[key]) gaObject[key] = data[key];
        });
        if(Object.keys(gaObject).length > 0) {
            dataLayer.push(gaObject);
        }
    }
    catch (e) {}
}

export { gaEvent, gaUserId, gaPush, gaPushObject };