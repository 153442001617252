import $ from 'jquery';
import {ubeHostFallBack} from '../util/fallback';
import {ubeCookie, cookies} from '../util/cookie';
import {gaPush} from "../util/ga";
import { isNoCameraStream } from '../util/device';
import { getImageSize } from '../util/faceHelpers';

(function () {

    function toggleLoader(visible) {
        console.log("UBE Toggle loader " + visible);
        if ($.ube && $.ube.toggleLoader) $.ube.toggleLoader(visible);
    }

    function showPopup(message) {
        if ($.ube && $.ube.showPopup)
            $.ube.showPopup(message);
        else {
            console.log(message);
            alert(message);
        }
    }

    $.fn.ubeFaceLocal = function (name, options) {

        var container = $(this).first();
        var form = container.find("form");
        window.backupLocalStorage = {};
        window.backupLocalStorage.setItem = function(key, value) {
            window.backupLocalStorage[key] = value;
        }
        window.backupLocalStorage.getItem = function(key) {
            return window.backupLocalStorage[key];
        }
        window.backupLocalStorage.removeItem = function(key) {
            delete window.backupLocalStorage[key];
        }
        ubeHostFallBack();

        const host = $.ube.host;
        let tryCount = 1;

        function onError(event, data) {
            if (event === 'limit') {
                const expires = "expires=Thu, 01 Jan 1970 00:00:00 UTC";
                ubeCookie(cookies.UBE_AGE_VERIFIED_TOKEN, '', expires);
                ubeCookie(cookies.UBE_AGE_VERIFIED, '', expires);
                ubeCookie(cookies.UBE_FACE_BEFORE, '', expires);
                try {
                    if (localStorage) {
                        localStorage.removeItem(cookies.UBE_AGE_VERIFIED_TOKEN);
                        localStorage.removeItem(cookies.UBE_AGE_VERIFIED);
                        localStorage.removeItem(cookies.UBE_FACE_BEFORE);
                    }
                } catch (error) {
                    window.backupLocalStorage.removeItem(cookies.UBE_AGE_VERIFIED_TOKEN);
                    window.backupLocalStorage.removeItem(cookies.UBE_AGE_VERIFIED);
                    window.backupLocalStorage.removeItem(cookies.UBE_FACE_BEFORE);
                }
            }
            if(options.onSubmissionError) options.onSubmissionError(event, data);
        }

        function onSuccess(result) {
            if(result && result.data) {
              if(result.data.sessionKey) {

                  const d = new Date();
                  const faceDate = new Date();
                  let expires = null;
                  let faceExpires = null;
                  d.setTime(d.getTime() + (50*60*1000));
                  faceDate.setTime(faceDate.getTime() + (120*60*1000));
                  expires = "expires="+ d.toUTCString();
                  faceExpires = "expires="+ faceDate.toUTCString();
                  ubeCookie(cookies.UBE_SESSION_KEY_REG, result.data.sessionKey, expires);
                  ubeCookie(cookies.UBE_FACE_BEFORE, true);
                  ubeCookie(cookies.UBE_AGE_VERIFIED, "face", faceExpires);
                  try {
                      if (localStorage) {
                          localStorage.setItem(cookies.UBE_FACE_BEFORE, true);
                          localStorage.setItem(cookies.UBE_AGE_VERIFIED, "face");
                      }
                  } catch (error) {
                      window.backupLocalStorage.setItem(cookies.UBE_FACE_BEFORE, true);
                      window.backupLocalStorage.setItem(cookies.UBE_AGE_VERIFIED, "face");
                  }

              }

              if(options.onSubmissionSuccess) options.onSubmissionSuccess(result.data);
          }
        }

        function loadTemplate() {
            const host = $.ube.host;
            const template_url = options.template || (host + "/template/" + name);
            container.load(template_url, initFace);
        }

        function initFace() {
            container.find(".ube-visibility-show-for-method, .ube-visibility-show-for-megafonMethod, .ube-visibility-show-for-documentMethod").hide();
            container.find(".ube-visibility-show-for-faceMethod").show();
            gaPush("qr_age", "fr");
            faceapi.nets.tinyFaceDetector.loadFromUri(host + '/js/plugin/')
                .then(() => {
                    initializeFaceCapture();
                })
            
        }

        function initializeFaceCapture() {
            $(".ube-face-error").hide();
            $(".ube-face-container").show();

            var renderContainer = container.find(".ube-camera-container");
            var mode;
            var renderTarget = container.find(".ube-camera-render");
            var captureButton = container.find(".ube-camera-capture");
            var fallbackTarget = container.find(".ube-camera-fallback");
            if (renderTarget.data("init")) return false;
            renderTarget.data("init", true);

            function sendImageToServer(imageBase64, callback, type, imageData) {
                toggleLoader(true);

                gaPush("dl_av_fr_process", `send - form - fr_${type}`, "Submit-Photo");

                $.ajax({
                    url: `${host}/api/log/verifyFace/${name.replace('-face', '-reg')}`,
                    data: JSON.stringify({ image: imageBase64, type, imageData }),
                    type: "POST",
                    processData: false,
                    cache: false,
                    dataType: "json",
                    contentType: 'application/json',
                    crossDomain: true,
                    error: function (err) {
                        toggleLoader(false);
                        console.error("Error from FR API");
                        console.error(err);
                        gaPush("AV", "AV-FR-Failed", "Submit-Photo-Failed-Technical");
                    },
                    success: function (data) {
                        toggleLoader(false);
                        console.log("Success from FR API");
                        console.log(data);
                        if (data) {
                            
                            if (data.message === "Success") {
                                if (callback) callback();
                                onSuccess(data);
                            }
                            else if (tryCount >= 2) {
                                if (callback) callback();
                                onError("limit", data);
                                gaPush("AV", "AV-FR-Failed-Details", data.message);
                            }
                            else if (data.message) {
                                tryCount++;
                                console.log(data.message);
                                gaPush("AV", "AV-FR-Failed-Details", data.message);

                                showPopup("Ой! Похоже, что вы выглядите слишком молодо.<br><br> <small>Возможно вы не выполнили одно из условий. В любом случае стоит попробовать еще раз, чтобы изображение получилось более чётким, не засвеченным и не слишком затемненным.</small>");
                            }
                        }
                    }
                });
            }

            function renderCameraCapture() {
                if (isNoCameraStream) return renderFileUpload();
                fallbackTarget.hide();
                mode = "stream";
                renderTarget.html("<video></video>").show();
                renderContainer.addClass("ube-camera-option-capture").removeClass("ube-camera-option-upload");
                var video = renderTarget.find("video")[0];

                if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {

                    video.setAttribute('autoplay', '');
                    video.setAttribute('muted', '');
                    video.setAttribute('playsinline', '');

                    var constraints = {
                        audio: false,
                        video: {
                            facingMode: 'user'
                        }
                    };

                    var stopVideoCapture = function() {
                        console.log("stopVideoCapture()");
                        video.pause();
                        video.src = "";
                        if (video.srcObject) {
                            video.srcObject.getTracks().forEach(function(track) {track.stop()});
                        }
                        renderTarget.html("");
                        renderTarget.data("init", false);
                    };

                    form.on("stopVideoCapture", function () {
                        console.log("Handling stop function");
                        stopVideoCapture();
                    });

                    navigator.mediaDevices.getUserMedia(constraints)
                        .then(function (stream) {
                            video.srcObject = stream;
                            console.log("Video capture init success");
                            gaPush("AV", "AV-FR-Mode", "AV-Stream");

                            var canvas = $("<canvas style='visibility:hidden;display:block;position: absolute;top:-2000px;left:-2000px'></canvas>").appendTo("body")[0];

                            const img = new Image();

                            function getImage() {
                                console.log("Capturing video");
                  
                                canvas.width = video.videoWidth;
                                canvas.height = video.videoHeight;
                                canvas.getContext('2d').drawImage(video, 0, 0);
                                const imageData = canvas.toDataURL('image/png');
                                console.log("Image data:");
                                return imageData;
                            }
                            setTimeout(() => {
                                scanFace().then(res => {
                                    sendImageToServer(res.image, stopVideoCapture, 'auto', res.imageData);
                                })
                            }, 500);

                            const scanFace = () => {
                              return new Promise(function cb(resolve, reject) {
                                const input = getImage();
                                img.onload = function() {
                                  faceapi.detectSingleFace(img, new faceapi.TinyFaceDetectorOptions())
                                    .then(res => {
                                        if (!res) cb(resolve, reject)
                                        else {
                                            const imageData = {
                                                width: img.width,
                                                height: img.height,
                                                sizeKB: getImageSize(img.src).toFixed(3)
                                            }
                
                                            const aggregatedImageData = {
                                                initial: imageData,
                                                resized: imageData
                                            }
                                            resolve({
                                                image: img.src,
                                                imageData: aggregatedImageData
                                            })
                                        };
                                    });
                                }
                                img.src = input;
                              })
                            }


                        })
                        .catch(function (e) {
                            console.log("Capture video error 1");
                            renderFileUpload();
                        });
                } else {
                    console.log("Capture video error 2");
                    renderFileUpload();
                }

            }

            function renderFileUpload() {
                mode = "upload";
                renderContainer.removeClass("ube-camera-option-capture").addClass("ube-camera-option-upload");
                fallbackTarget.show();
                renderTarget.hide();

                var dummyFile = $("<input type=\"file\" accept=\"image/*\" capture=\"user\" style='width:0;height:0;position:absolute;'/>").appendTo("body");

                dummyFile.off("change").change(function () {
                    console.log("File field changed");
                    var reader = new FileReader();
                    
                    reader.addEventListener("load", function () {
                        var imageData = this.result;
                        console.log("Image data:");
                        console.log(imageData);
                        const img = new Image;
                        img.onload = function() {
                        faceapi.detectSingleFace(img, new faceapi.TinyFaceDetectorOptions())
                            .then(res => {
                                if (!res) {
                                    alert('Лицо не распознано')
                                }
                                else {
                                    const imageData = {
                                        width: img.width,
                                        height: img.height,
                                        sizeKB: getImageSize(img.src).toFixed(3)
                                    }
        
                                    const aggregatedImageData = {
                                        initial: imageData,
                                        resized: imageData
                                    }
        
                                    console.log("Sending FR API request");

                                    sendImageToServer(img.src, null, 'upload', aggregatedImageData);
                                };
                            });
                        }
                        img.src = imageData;
                    }, false);

                    reader.readAsDataURL(this.files[0]);
                });

                captureButton.add(".ube-camera-option-upload").off("click").click(function (e) {
                    console.log("Capture upload clicked");
                    e.preventDefault();
                    dummyFile.click();
                    return false;
                });
            }
            renderCameraCapture();
        }

        const {isTemplatePreloaded, onSubmissionSuccess} = options;

        if(isTemplatePreloaded) {
            initFace()
        } else {
            loadTemplate()
        }
    };
})();