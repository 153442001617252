import {ubeCookie, cookies} from '../../util/cookie';

(function () {

    const callbackBlacklist = ['mrl-lpe-global-face'];

    $.fn.ubeScanpackFace = function (name, callback, options) {
        const container = $(this).first();

        if (!container || container.length === 0) console.error("UBE - Отсутствует контейнер");
        if (!name || name === "") return container.html("UBE - Не указан параметр name");

        name = name.replace("-reg", "-face");

        const ubeFaceOptions = {
            isTemplatePreloaded: false,
            onSubmissionSuccess: function (result) {
                callback(true, result);
            },
            onSubmissionError: function (result) {
                container.find(".ube-hide-on-fail").hide();
                container.find(".ube-show-on-fail").show();
                if (!callbackBlacklist.includes(name)) callback(false, result);
            },
            ...options
        }

        container.ubeFace(name, ubeFaceOptions);
    };
})();