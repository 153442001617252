let debug = false;

function ubeHostFallBack() {
    if (!$.ube) $.ube = {};
    if (!$.ube.host) {
        $.ube.host = "https://ube-test.pmsm.org.ru";
        if (debug) $.ube.host = "http://localhost:8080";
    }
    if (!$.ube.leadHost) {
        $.ube.leadHost = "https://preprod-lead.pmsm.org.ru";
    }
}

function ubeHostAdd(formName) {
    if((typeof formName === 'string' || formName instanceof String)
        && formName && !/^http(s)?:\/\//.test(formName) && !/\//.test(formName) && /-/.test(formName) && formName.length < 64) {
        ubeHostFallBack();
        return $.ube.host + "/form/" + formName;
    } else {
        return formName;
    }
}

export { ubeHostFallBack, ubeHostAdd };