import $ from 'jquery';

$.fn.ubeStopwatch = function() {
  let seconds = 0;
  let stopwatchTimeout;
    
  function startStopwatch() {
    console.log('UBE :: Stopwatch started');
    const initialMoment = Date.now();
    let interval = 1000;
    let expectedMoment = initialMoment + 1000;

    function countSecond() {
      seconds++;
      const difference = Date.now() - expectedMoment;
      expectedMoment += 1000;
      stopwatchTimeout = setTimeout(() => {
        countSecond()
      }, interval - difference);
      console.log('seconds:', seconds);
    }

    stopwatchTimeout = setTimeout(() => {
      countSecond();
    }, interval)
  }

  function stopStopwatch() {
    console.log('UBE :: Stopwatch stopped on ' + seconds + ' seconds');
    clearTimeout(stopwatchTimeout);
    const result = seconds;
    seconds = 0;
    return result;
  }

  function pauseStopwatch() {
    console.log('UBE :: Stopwatch paused on ' + seconds + ' seconds');
    clearTimeout(stopwatchTimeout);
    return seconds;
  }
     
  return {
    start() {
      startStopwatch();
    },
    stop() {
      return stopStopwatch();
    },
    pause() {
      return pauseStopwatch();
    }
  }
}