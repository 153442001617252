import $ from 'jquery';
import {ubeHostFallBack} from '../../util/fallback';
import {ubeCookie, cookies, sessionExpiration} from '../../util/cookie';
import {gaPush} from "../../util/ga";

(function () {

    $.fn.ubeScanpackLogin = function (name, callback, options) {
        const isDocuments = options && options.documents;
        const isFaceVerified = ubeCookie(cookies.UBE_FACE_BEFORE) && ubeCookie(cookies.UBE_AGE_VERIFIED_TOKEN) && ubeCookie(cookies.UBE_AGE_VERIFIED);
        const isIDXEnabled = options && options.idx && !isFaceVerified;
        const isTrial = options && options.trial

        function setCookies(sessionKey, event) {
            ubeCookie(cookies.UBE_WIDGET_SESSION_KEY, sessionKey, sessionExpiration());
            if (event === 'registration') {
                ubeCookie(cookies.UBE_WIDGET_REGISTER, 'Y');
            } else if (event === 'login') {
                ubeCookie(cookies.UBE_WIDGET_LOGIN, 'Y');
            }
        }

        function getInfo(sessionKey) {
            return $.get(`${$.ube.host}/esb/${name}/avUserStatus?sessionKey=${sessionKey}`)
                .fail(() => console.log('error'))
        }
        const data = (options && options.data) || {};

        var container = $(this).first();

        if(!container || container.length === 0) console.error("UBE - Отсутствует контейнер");
        if(!name || name === "") return container.html("UBE - Не указан параметр name");

        const phoneSendName = name.replace("-reg", "-phone-send");
        const phoneCheckName = name.replace("-reg", "-phone-check");
        const idxName = name.replace("-reg", "-idx");

        container.empty();
        const phoneSendContainer = $('<div class="ube-form-phone-send"></div>').appendTo(container);
        const phoneCheckContainer = $('<div class="ube-form-phone-check"></div>').hide().appendTo(container);
        const registrationContainer = $('<div class="ube-form-register"></div>').hide().appendTo(container);
        const avContainer = $('<div class="ube-form-docs"></div>').hide().appendTo(container);
        const idxContainer = $('<div class="ube-form-idx"></div>').hide().appendTo(container);
        const popupContainer = $('<div class="ube-popup"></div>').hide().appendTo($('body'));

        const isFRCookieExists = ubeCookie(cookies.UBE_JWT_FACE_TOKEN) || false;

        const handleTextVisibility = () => {
            if ((isDocuments || isIDXEnabled) && !isFRCookieExists) {
                $('.ube-av0-phone-send, .ube-av0-phone-check').show();
                $('.ube-av1-phone-send, .ube-av1-phone-check').hide();
            } else {
                $('.ube-av1-phone-send, .ube-av1-phone-check').show();
                $('.ube-av0-phone-send, .ube-av0-phone-check').hide();
            }
        }

        function loadPhoneSend() {
            const formData = {
                ...data,
                phone: "",
            }

            phoneSendContainer.show().ube(phoneSendName, {
                data: formData,
                isTemplatePreloaded: false,
                onFormLoad: handleTextVisibility,
                onSubmissionSuccess: function (submissionId, userId, result) {
                    switch (result.event) {
                        case 'success':
                            loadPhoneCheck(result.data.sessionKey, result.data.phone);
                            break;
                    }
                }
            });
        }

        function backToPhoneSend() {
            phoneCheckContainer.hide();
            phoneSendContainer.show();
            $(".ube-phone-send-form [name=phone]").focus();
        }

        function loadPhoneCheck(sessionKey, phone) {
            phoneSendContainer.hide();
            let isFormSubmitted = false;
            const isNewUser = !ubeCookie(cookies.UBE_WIDGET_REGISTER) && !ubeCookie(cookies.UBE_WIDGET_LOGIN);
            const isNewUserLabel = isNewUser ? "NewUser" : "ReturningUser";
            phoneCheckContainer.show().ube(phoneCheckName, {
                sessionKey: sessionKey,
                isTemplatePreloaded: false,
                loadFormDataFromSession: true,
                data: {
                    phone
                },
                onFormLoad: handleTextVisibility,
                onSubmissionSuccess: function (submissionId, userId, result) {
                    switch (result.event) {
                        case 'cancel':
                        case 'close':
                            backToPhoneSend();
                            break;
                        case 'verified':
                            if(!isFormSubmitted) {
                                isFormSubmitted = true;
                                if (isIDXEnabled) showIDXPage(result.data.sessionKey, phone)
                                else showRegistrationPage(result.data.sessionKey, phone)
                            }
                            break;
                        case 'loginSuccess':
                            if(!isFormSubmitted) {
                                isFormSubmitted = true;
                                setCookies(result.data.sessionKey, 'login');
                                const response = {
                                    event: result.event,
                                    sessionKey: result.data.sessionKey,
                                    userId
                                };
                                getInfo(result.data.sessionKey)
                                    .done(response => {
                                        const { av, trialAccess } = response;
                                        const avStatus = +av;
                                        if (isIDXEnabled || isDocuments || isTrial) {
                                            if (avStatus === 1 || (isTrial && avStatus === 0 && trialAccess)) {
                                                console.log("UBE Login result", response);
                                                if (trialAccess) console.log("UBE :: user has trial access");
                                                callback(result.event, result, 'login', 1);
                                            } else {
                                                showAVPage(result.data.sessionKey, 'login');
                                            }
                                        } else {
                                            callback(result.event, result, 'login', avStatus);
                                        }

                                    })

                                console.log("UBE Login result", response);
                            }
                            break;

                    }
                }
            });
        }

        function showIDXPage(sessionKey, phone) {
            phoneCheckContainer.hide();
            idxContainer.appendTo(container).show().ube(idxName, {
                isTemplatePreloaded: false,
                sessionKey: sessionKey,
                loadFormDataFromSession: false,
                data: {
                    phone: phone
                },
                setFieldValue: function (key, value, common) {
                    if (key === 'agreeWithRules') $('#agreeWithRules').prop('checked', false);
                    else common(key, value);
                },
                onSubmissionSuccess: function (submissionId, userId, result) {
                    switch (result.event) {
                        case 'success':
                            showRegistrationPage(result.data.sessionKey, phone, result);
                    }
                }
            })
        }

        function showRegistrationPage(sessionKey, phone, result) {
            phoneCheckContainer.hide();
            idxContainer.hide();
            registrationContainer.show().ube(name, {
                isTemplatePreloaded: false,
                sessionKey: sessionKey,
                data: {phone: phone},
                onFormLoad: function() {
				if (isIDXEnabled) {
                        $(".ube-av1").hide();
                    } else {
                        $(".ube-av0").hide();
                    }
                },

                setFieldValue: function (key, value, common) {
                    if (result && key === 'birthDate' && result.birthDate) {
                        var v = result.birthDate.split(".").join("");
                        $('#birthDateField').val(v);
                    } else if (result && key === 'firstName' && result.firstName) {
                        $('#firstNameField').val(result.firstName)
                    } else if (result && key === 'lastName' && result.lastName) {
                        $('#lastNameField').val(result.lastName)
                    } else if (result && key === 'consent') {
                        $('#checkboxField').prop('checked', true);
                    } else if (result && key === 'idxId' && result.idxID) {
                        $('#idxID').val(result.idxID)
                    } else {
                        common(key, value);
                    }
                },
                onSubmissionSuccess: function (submissionId, userId, result) {
                    const response = {
                        event: result.event,
                        sessionKey: result.data.sessionKey,
                        userId
                    };
                    switch (result.event) {
                        case "alreadyVerified":
                            handleAV1Result(result, response);
                            break;
                        case "registered":
                            handleAV0Result(result, response);
                            break;
                        case "2optin":
                            toOptInEmail(submissionId, userId, result);
                            break;
                    }
                }
            });
        }

        const handleAV1Result = (result, response) => {
            setCookies(result.data.sessionKey, 'registration');
            console.log("UBE Register AV1 result", response);
            callback(result.event, result, 'registration', 1);
        }

        const handleAV0Result = (result, response) => {
            setCookies(result.data.sessionKey, 'registration');
            console.log("UBE Register result", response);
            if (isDocuments || isIDXEnabled) {
                showAVPage(response.sessionKey, 'registration')
            } else {
                $('#popup-content').hide();
                $('#registration-content').hide();
                callback(result.event, result, 'registration', 1);
            }
        }

        function showAVPage(sessionKey, originalEvent) {
            const gaUJEvent = originalEvent === 'registration' ? 'reg' : 'auth';
            gaPush("qr_age", "idx - after_" + gaUJEvent, sessionStorage.getItem('promoCampaignName'));
            registrationContainer.hide();
            phoneCheckContainer.hide();
            avContainer.show().ubeAV(`${$.ube.host}/main/${name}`, sessionKey, {
                data: {
                    entity: name,
                },
                isTemplatePreloaded: false,
                onSubmissionSuccess: function (submissionId, userId, result) {
                    switch (result.event) {
                        case "instant":
                            callback(result.event, result, originalEvent, 1);
                            break;
                        case "later":
                            callback(result.event, result, originalEvent, 0);
                            break;
                        case "delayed":
                            if (!ubeCookie(cookies.UBE_DOCUMENTS_PROVIDED)) {
                                const dateTarget = new Date();
                                dateTarget.setDate(dateTarget.getDate() + 7);  // 1 week
                                ubeCookie(cookies.UBE_DOCUMENTS_PROVIDED, 'Y', dateTarget);
                            }
                                if (isTrial) callback(result.event, result, originalEvent, 1)
                                else callback(result.event, result, originalEvent, 0);
                            break;
                        default:
                            console.error("UBE :: Unknown AV result event: " + result.event);
                            break;
                    }
                },
                onSessionError: function (message, sessionKey) {
                    $.ube.showPopup("Ошибка сессии");
                }
            });
        }

        function toOptInEmail (submissionId, userId, result) {
            popupContainer.ube("ube2-optin", {
                data: result.data.data,
                template: result.data.template,
                onFormLoad: function () {
                    popupContainer.fadeIn();
                },
                onSubmissionSuccess: function (submissionId, userId, result) {
                    const response = {
                        event: result.event,
                        sessionKey: result.data.sessionKey,
                        userId
                    };
                    popupContainer.fadeOut();
                    switch (result.event) {
                        case "alreadyVerified":
                            handleAV1Result(result, response);
                            break;
                        case "registered":
                            handleAV0Result(result, response);
                            break;
                        case "close":
                            break;
                    }
                }
            });
        }
        loadPhoneSend();
    };
})();