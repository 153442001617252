import {Block} from "./block";

export class Survey extends Block {
    async run(key, state, cb) {
        this.options.survey(() => {
                cb();
            },
            state,
            async (data) => {
                const response = await fetch(`${$.ube.host}/api/session/${this.name}/survey/?sessionKey=${state.sessionKey}`,
                    {
                        method: 'POST',
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(data)
                    });

                if (response.ok) return await response.json();
            });
    }

    close() {
        $('.survey-screen').hide();
    }
}