import $ from 'jquery';
import {Widget} from "./Widget";

export class LoyaltyHistory extends Widget {
    constructor() {
        super();
        this.launch = this.launch.bind(this);
        this.display = this.display.bind(this);
        this.showAll = false;
        this.limit = 5;
    }

    static showCode(code) {
        const popup = $("#personal-code .personal-code__popup.js-popup-ok");
        //popup.find(".personal-code__popup-text").html('<div class="personal-code__popup-text-bold">ВАШ КОД</div><br> <div class="ube-popup-code-field" style="width: 256px;height: 51px;border: 1px solid #4F4F4F;margin:auto;"><span style="font-family: Noto Sans;font-style: normal;font-weight: bold;line-height: normal;font-size: 20px;text-align: center;letter-spacing: 3.6px;color: #D6BA81;display:block;margin-top:10px;">'+code+'</span></div>');
        popup.removeClass("is-hide");
    }

    static showAbsent() {
        const popup = $("#personal-code .personal-code__popup.js-popup-alert");
        popup.find(".personal-code__popup-text")
            .html('Данный номер телефона отсутствует в базе данных потребителей IQOS. Станьте участником <a style="text-align:center;display:block;color:#ffffff;" href="/about-iqos-club/">IQOS Club,<br/> чтобы получить свой код рекомендаций.</a>');
        popup.removeClass("is-hide");
    }

    /**
     *
     * @param {string} error error text
     */
    static showError(error) {
        const popup = $("#personal-code .personal-code__popup.js-popup-alert");
        popup.find(".personal-code__popup-text").html(error);
        popup.removeClass("is-hide");
    }

    /**
     * Check if widget should launch on this context
     * @param context {Context} widget context
     * @returns {boolean}
     */
    isDefinedAt(context) {
        return false//context.host.includes("uat-ecom.iqos.ru") && context.path === "/loyalty-status/"
    }

    /**
     * Launch widget
     * @param context {Context} widget context
     */
    launch(context) {
        if (!this.template) {
            $.get($.ube.host + "/template/loyalty-history").done(result => {
                this.template = result;
                this.display();
            })
        } else
            this.display();
    }

    display() {
        //Удаляем старый контейнер если есть
        $(".loyalty-history").remove();

        const template = $(this.template);
        const holder = template.find(".loyalty-history_holder");
        const entry = template.find(".loyalty-history_entry").clone();

        holder.empty();

        const data = [
            {
                created: "21 сентября",
                text: "Покупка устройства или аксессуаров IQOS",
                points: 500
            },
            {
                created: "20 сентября",
                text: "Покупка другом по вашему коду рекомендаций",
                points: 304
            },
            {
                created: "19 сентября",
                text: "Заполнение профиля",
                points: 101
            },
            {
                deactivated: "18 сентября",
                deactivatedText: "Удаление ранее заполненной информации в профиле",
                points: 500
            },
            {
                deactivated: "17 сентября",
                deactivatedText: "Удаление аккаунта социальной сети в личном профиле",
                points: 491
            },
            {
                created: "15 сентября",
                text: "Покупка устройства или аксессуаров IQOS",
                points: 354
            },
            {
                created: "10 сентября",
                text: "Покупка другом по вашему коду рекомендаций",
                points: 100
            },
            {
                created: "9 сентября",
                text: "Заполнение профиля",
                points: 105
            },
        ];

        const dataItemToDom = item => {
            const entryCopy = entry.clone();

            const points = parseInt(item.points) || 0;
            let pointText = "Баллов";
            if((points % 10) === 1) pointText = "Балл";
            else if((points % 10) < 5 && (points % 10) > 1) pointText = "Балла";

            if (item.deactivated && item.deactivatedText) {
                entryCopy.find(".loyalty-history_label").text("У вас списаны баллы");
                entryCopy.find(".loyalty-history_date").text(item.deactivated);
                entryCopy.find(".loyalty-history_description").text(item.deactivatedText);
                entryCopy.find(".loyalty-history_points").text("- "+points+" "+pointText);
            } else {
                entryCopy.find(".loyalty-history_label").text("Вам начислены баллы");
                entryCopy.find(".loyalty-history_date").text(item.created);
                entryCopy.find(".loyalty-history_description").text(item.text);
                entryCopy.find(".loyalty-history_points").text("+ "+points+" "+pointText);
            }

            holder.append(entryCopy);
        };

        const showAllButton = template.find(".loyalty-history_button a");

        showAllButton.click(e => {
            e.preventDefault();
            showAllButton.hide();
            data.slice(this.limit).forEach(dataItemToDom);
            return false;
        });

        if(data.length > 0) {

            data.slice(0, this.limit).forEach(dataItemToDom);

            if(data.length > this.limit) showAllButton.show();
            else showAllButton.hide();

            $(".points-progress").after(template);
        }
    }
}

export default LoyaltyHistory;
