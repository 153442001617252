import { cookies, ubeCookie } from "../../../util/cookie";
import { gaPush } from "../../../util/ga";
import { Block } from "./block";

export class AV extends Block {
    run(key, state, cb) {
        const gaUJEvent = state.originalEvent === 'registration' ? 'reg' : 'auth';
        const sessionKey = state.sessionKey;
        const { options } = this;
        const target = this.initContainers('login', 'docs');
    
        gaPush("qr_age", "idx - after_" + gaUJEvent, sessionStorage.getItem('promoCampaignName'));

        target.show().ubeAV(`${$.ube.host}/main/${this.name}`, sessionKey, {
            data: {
                entity: this.name,
            },
            isTemplatePreloaded: false,
            onSubmissionSuccess: function (submissionId, userId, result) {
                switch (result.event) {
                    case "instant":
                        cb({
                            avResult: 'instant' 
                        },
                        {
                            av: 1,
                            sku: false,
                            sessionKey: result.data.sessionKey,
                            result,
                            originalEvent: state.originalEvent
                        })
                        break;
                    case "later":
                        cb({ avResult: 'delayed' },
                        {
                            av: 0,
                            sku: true,
                            sessionKey: result.data.sessionKey,
                            result,
                            originalEvent: state.originalEvent
                        })
                        break;
                    case "delayed":
                        if (!ubeCookie(cookies.UBE_DOCUMENTS_PROVIDED)) {
                            const dateTarget = new Date();
                            dateTarget.setDate(dateTarget.getDate() + 7);  // 1 week
                            ubeCookie(cookies.UBE_DOCUMENTS_PROVIDED, 'Y', dateTarget);
                        }
                        if (options.isTrial) cb({
                            avResult: 'instant' 
                        },
                        {
                            av: 1,
                            sku: false,
                            sessionKey: result.data.sessionKey,
                            result,
                            originalEvent: state.originalEvent
                        })
                        else cb({ avResult: 'delayed' },
                        {
                            av: 0,
                            sku: true,
                            sessionKey: result.data.sessionKey,
                            result,
                            originalEvent: state.originalEvent
                        })
                        break;
                    default:
                        console.error("UBE :: Unknown AV result event: " + result.event);
                        break;
                }
            },
            onSessionError: function () {
                $.ube.showPopup("Ошибка сессии");
            }
        });
    }

    close() {
        $('.ube-form-docs').remove();
    }
}
