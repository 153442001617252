import $ from 'jquery';
import {Widget} from "./Widget";
import {ubeHostFallBack} from '../util/fallback';

export class LeadTrialForm extends Widget {
    constructor() {
        super();
    }

    /**
     * Check if widget should launch on this context
     * @param context {Context} widget context
     * @returns {boolean}
     */
    isDefinedAt(context) {
        return context.path === "trial-request/";
    }

    /**
     * Launch widget
     * @param context {Context} widget context
     */
    launch(context) {
        const form = $(".test-drive__order");

        ubeHostFallBack();
        var wasShown = false;

        form.ube($.ube.leadHost + "/main/organic-reg", {
            onSubmissionSuccess: function(submissionId, userId, result) {
                window.location.href = "/trial-request/thanks.php";
            },
            onFormLoad: function() {
                // События по полям формы
                DDM.initFormEvents(
                    "Тест-драйв",
                    '.js-test-drive-form',
                    '.input-text',
                    '.input-text__input',
                    '.input-text__error',
                    '.input-text__label',
                    '.js-ube-lead-submit'
                );

                $('.js-rta-phone').focus(function () {
                    if (!$(this).val() || $(this).val() == '') {
                        $(this).val("+7 (").putCursorAtEnd();
                    }
                });

                $('.js-rta-phone').keyup(function () {
                    if ($(this).val() === "+7 (8") {
                        $(this).val("+7 (");
                    }
                    if ($(this).val() === "+7 (7") {
                        $(this).val("+7 (");
                    }
                });
            },
            onValidationSuccess: function() {
                // Событие отправки формы
                DDM.pushEventX('lead', {
                    label: location.pathname,
                    promocode: $('#promo').val(),
                    city: $('#city').val(),
                    metro: $('#metro').val()
                }, true);
            },
            setFieldValid: function(key, value, common) {
                if (key === "city") {
                    var field = $("#leads-trial-form [name=city]");
                    var data = field.data('data-object') || {};
                    if (data.hasTrial === false && !wasShown) {
                        wasShown = true;
                        $('.rta-popup-wrapper').show();
                    }
                }
                common(key, value);
            }
        });
    }
}

export default LeadTrialForm;