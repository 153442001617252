import { cookies, ubeCookie } from "../../../util/cookie";
import { Block } from "./block";
import { gaPush } from "../../../util/ga";

export class Face extends Block {

    run(key, state, cb) {
        const target = this.initContainers('face');
        const faceName = this.name.replace('-reg', '-face');
        const { options } = this;
        const faceSuccess = {
            face: true,
            av: 1,
            faceAV: 1
        };
        const faceFailure = {
            face: true,
            av: 0,
            faceAV: 0
        }

        sessionStorage.setItem('isFaceSkipped', state.shouldSkipFace);

        if(state.shouldSkipFace) {
            gaPush('dl_qr_av_fr_skip', 'click - skip - form - fr');
            return cb({ avStatus: 1 }, faceSuccess);
        }

        gaPush('dl_qr_av_fr_process', 'view - screen_fr');

        return target.ubeFace(faceName, {
            isTemplatePreloaded: false,
            onSubmissionSuccess: function () {
                if(options.onFaceSuccess) {
                    options.onFaceSuccess(() => {
                        cb({ avStatus: 1 }, faceSuccess); 
                    })
                } else {
                    cb({ avStatus: 1 }, faceSuccess);
                }
            },
            onSubmissionError: function () {
                target.find(".ube-hide-on-fail").hide();
                target.find(".ube-show-on-fail").show();
                cb({ avStatus: 0 }, faceFailure);
            },
            ...this.options.face
        });
    }

    close() {
        $('.ube-widget-face-container').hide();
    }
}