import MD5 from '../libraries/md5';
import getFonts from "../libraries/font";
import CryptoJS from '../libraries/sha1';

class Fingerprint {

    #canvasFingerprint;
    #audioFingerprint;
    #fontFingerprint;


    calculateCanvasBase64() {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const txt = 'CANVAS_FINGERPRINT';

        ctx.textBaseline = "top";
        ctx.font = "14px 'Arial'";
        ctx.textBaseline = "alphabetic";
        ctx.fillStyle = "#f60";
        ctx.fillRect(125,1,62,20);
        ctx.fillStyle = "#069";
        ctx.fillText(txt, 2, 15);
        ctx.fillStyle = "rgba(102, 204, 0, 0.7)";
        ctx.fillText(txt, 4, 17);

        this.#canvasFingerprint = MD5(canvas.toDataURL());
    }

    calculateAudioFingerprint() {
        let context, pxi_compressor, pxi_oscillator, hash, pxi_full_buffer_hash, pxi_output;

        try {
            if (context = new(window.OfflineAudioContext || window.webkitOfflineAudioContext)(1, 44100, 44100), !context) {
                this.#audioFingerprint = ""
                pxi_output = 0;
            }

            pxi_oscillator = context.createOscillator();
            pxi_oscillator.type = "triangle";
            pxi_oscillator.frequency.value = 1e4;

            pxi_compressor = context.createDynamicsCompressor();
            pxi_compressor.threshold && (pxi_compressor.threshold.value = -50);
            pxi_compressor.knee && (pxi_compressor.knee.value = 40);
            pxi_compressor.ratio && (pxi_compressor.ratio.value = 12);
            pxi_compressor.reduction && (pxi_compressor.reduction.value = -20);
            pxi_compressor.attack && (pxi_compressor.attack.value = 0);
            pxi_compressor.release && (pxi_compressor.release.value = .25);

            pxi_oscillator.connect(pxi_compressor);
            pxi_compressor.connect(context.destination);

            pxi_oscillator.start(0);
            context.startRendering();
            context.oncomplete = (evnt) => {
                pxi_output = 0;
                var sha1 = CryptoJS.algo.SHA1.create();
                for (var i = 0; i < evnt.renderedBuffer.length; i++) {
                    sha1.update(evnt.renderedBuffer.getChannelData(0)[i].toString());
                }
                hash = sha1.finalize();
                pxi_full_buffer_hash = hash.toString(CryptoJS.enc.Hex);
                this.#audioFingerprint = pxi_full_buffer_hash
                pxi_compressor.disconnect();
            }
        } catch (u) {
            pxi_output = 0;
            this.#audioFingerprint = ""
        }
    }

    calculateFontFingerprint() {
        const fonts = getFonts();

        this.#fontFingerprint = MD5(fonts);
    }

    get fingerprintData() {
        return {
            c: this.#canvasFingerprint,
            a: this.#audioFingerprint,
            f: this.#fontFingerprint
        }
    }
}

export { Fingerprint as default }