(function () {
    $.getCoupon = function (entity, sessionKey) {
        return $.get($.ube.host + "/api/session/coupon/" + entity + "?sessionKey=" + sessionKey)
            .done(result => {
                if (result) return result;
            })
            .fail(function (xhr, resp, text) {
                console.log("UBE :: Get Coupon ERROR");
                console.log(xhr, resp, text);
            })
    };
})();