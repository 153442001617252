const fontList = [
    "Andale Mono", "Arial", "Arial Black", "Arial Hebrew", "Arial MT", "Arial Narrow", "Arial Rounded MT Bold", "Arial Unicode MS",
    "Bitstream Vera Sans Mono", "Book Antiqua", "Bookman Old Style",
    "Calibri", "Cambria", "Cambria Math", "Century", "Century Gothic", "Century Schoolbook", "Comic Sans", "Comic Sans MS", "Consolas", "Courier", "Courier New",
    "Garamond", "Geneva", "Georgia",
    "Helvetica", "Helvetica Neue",
    "Impact",
    "Lucida Bright", "Lucida Calligraphy", "Lucida Console", "Lucida Fax", "LUCIDA GRANDE", "Lucida Handwriting", "Lucida Sans", "Lucida Sans Typewriter", "Lucida Sans Unicode",
    "Microsoft Sans Serif", "Monaco", "Monotype Corsiva", "MS Gothic", "MS Outlook", "MS PGothic", "MS Reference Sans Serif", "MS Sans Serif", "MS Serif", "MYRIAD", "MYRIAD PRO",
    "Palatino", "Palatino Linotype",
    "Segoe Print", "Segoe Script", "Segoe UI", "Segoe UI Light", "Segoe UI Semibold", "Segoe UI Symbol",
    "Tahoma", "Times", "Times New Roman", "Times New Roman PS", "Trebuchet MS", "Ubuntu",
    "Verdana", "Wingdings", "Wingdings 2", "Wingdings 3"
];

const CanvasFontDetector = function() {
    var baseFonts = ['monospace', 'sans-serif', 'serif'];

    var testString = "mimimimimimimimimimimimimimimimimimimimimimimimimimimi";

    var testSize = '72px';


    function fontFamily(fonts){
        let result = [];
        let arrayLength = fonts.length;

        for (let i = 0; i < arrayLength; i++) {
            result.push("'" + fonts[i] + "'");
        }

        result = result.join(", ");

        return result;
    }

    function CanvasTester(testString, testSize){
        let canvas = document.createElement("canvas");
        let context = canvas.getContext("2d");

        return function() {
            context.font = testSize + " " + fontFamily(arguments);
            let dim = context.measureText(testString);

            return dim.width;
        };
    }

    var test = new CanvasTester(testString, testSize);

    var defaultWidth = {};
    for (let index in baseFonts) {
        defaultWidth[baseFonts[index]] = test(baseFonts[index]);
    }

    function detect(font) {
        var detected = false;
        for (var index = 0, l = baseFonts.length; index < l; index++) {
            var width = test(font, baseFonts[index]);
            var matched = (width != defaultWidth[baseFonts[index]]);
            detected = detected || matched;
        }
        return detected;
    }

    this.detect = detect;
};

const getFonts = () => {
    let installedFonts = '';
    let d2 = new CanvasFontDetector();

    for (let index = 0; index < fontList.length; ++index) {
        var font = fontList[index];

        if(d2.detect(font)){
            installedFonts += fontList[index];
            installedFonts += ';';
        }
    }

    return installedFonts;
}

export { getFonts as default }