import $ from 'jquery';
import {Widget} from "./Widget";

export class RefCode extends Widget {
    constructor() {
        super();
    }

    static showCode(code) {
        const popup = $("#personal-code .personal-code__popup.js-popup-ok");
        //popup.find(".personal-code__popup-text").html('<div class="personal-code__popup-text-bold">ВАШ КОД</div><br> <div class="ube-popup-code-field" style="width: 256px;height: 51px;border: 1px solid #4F4F4F;margin:auto;"><span style="font-family: Noto Sans;font-style: normal;font-weight: bold;line-height: normal;font-size: 20px;text-align: center;letter-spacing: 3.6px;color: #D6BA81;display:block;margin-top:10px;">'+code+'</span></div>');
        popup.removeClass("is-hide");
        $("html, body").addClass("is-no-scroll");
    }

    static onPopupShow() {
        $('html, body').addClass('is-overflow-hidden');
        if(/iPad|iPhone|iPod/.test(window.navigator.userAgent)) {
            $('html, body').addClass('is-mobile-overflow');
        }
    }

    static showAbsent() {
        const popup = $("#personal-code .personal-code__popup.js-popup-alert");
        popup.find(".personal-code__popup-text")
            .html('Данный номер телефона отсутствует в базе данных потребителей IQOS. Станьте участником <a style="text-align:center;color:#ffffff;" href="/about-iqos-club/">IQOS Club</a>, чтобы получить свой код рекомендаций.');
        popup.removeClass("is-hide");

        RefCode.onPopupShow();
    }

    /**
     *
     * @param {string} error error text
     */
    static showError(error) {
        const popup = $("#personal-code .personal-code__popup.js-popup-alert");
        popup.find(".personal-code__popup-text").html(error);
        popup.removeClass("is-hide");

        RefCode.onPopupShow();
    }

    /**
     * Check if widget should launch on this context
     * @param context {Context} widget context
     * @returns {boolean}
     */
    isDefinedAt(context) {
        return (
            context.host.includes("iqos-ecom.de02.agima.ru") || context.host.includes("iqos.ru")
        ) && (
            context.path === "/loyalty-recommendations/" || context.path === "/dtereferralcode/"
        )
    }

    /**
     * Launch widget
     * @param context {Context} widget context
     */
    launch(context) {
        const form = $(".personal-code__item form");
        form.removeAttr("action").removeAttr("method");
        const field = form.find(".personal-code__field-input");

        $(".personal-code__popup .js-popup-close").off("click").click(function() {
            $(this).parents(".personal-code__popup").first().addClass("is-hide");
            $('html, body').removeClass('is-overflow-hidden');
            if(/iPad|iPhone|iPod/.test(window.navigator.userAgent)) {
                $(window).scrollTop($('.js-personal-code').offset().top);
                $('html, body').removeClass('is-mobile-overflow');
            }
        });

        field.on("focus", function() {
           if(!$(this).val() || $(this).val() === "") $(this).val("+7 (");
        });

        function showFieldError(text) {
            const parent = field.parent();
            parent.addClass('is-bounce');
            setTimeout(() => {
                parent.removeClass('is-bounce');
            }, 1000);
            return form.find(".error-message").html('<div id="phone-error" class="is-error">'+text+'</div>');
        }


        //$(".personal-code__item:first-child .personal-code__item-title").text("ПО НОМЕРУ ТЕЛЕФОНА");

        //$(".personal-code__item:first-child .personal-code__item-desc").text("Введите ваш номер мобильного телефона, чтобы узнать свой персональный код");

        function submitForm() {
            const value = field.val();

            if(!value || value === "") return showFieldError("поле необходимо заполнить");

            const valueFormatted = value.replace(/[^0-9]/g,"").replace(/^(7|8)/g,"");

            if(valueFormatted.length !== 10) return showFieldError("Введите телефон в формате +7 (9XX) 123-45-67");


            $(".js-loader").show();

            $.get($.ube.host + "/api/referral/code/send?phone=" + valueFormatted).done(function (data) {
                if(data && data.result === true) {
                    RefCode.showCode();

                    window.digitalData.events.push({
                        "category": "Lead",
                        "name": "Get Code Form Sent",
                        "label": "sms"
                    });

                } else if(data && data.error && data.error !== "") {
                    RefCode.showError(data.error);
                }
                else {
                    RefCode.showAbsent();
                }

            }).always(() => $(".js-loader").hide());

        }

        form.off("submit").submit(e => {
           e.preventDefault();
           submitForm();
           return false;
        });

        form.find("button").off("click").click(e => {
            e.preventDefault();
            submitForm();
            return false;
        })
    }
}

export default RefCode;