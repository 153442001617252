import $ from 'jquery';

$.scrollToElement = function (selector) {
    if (!selector) return false;
    var elements = $(selector);
    if (elements.length === 0) return false;
    var el = elements.first();

    elements.each(function () {
        if ($(this).offset().top < el.offset().top) el = $(this);
    });

    return el.focus();

    var elOffset = el.offset().top;
    var elHeight = el.height();
    var windowHeight = $(window).height();
    var offset;

    var windowTop = $(window).scrollTop();

    if (((elOffset - 64) > windowTop) && (elOffset + 64 < (windowTop +
        (window.innerHeight || document.documentElement.clientHeight)))) {
        //Do not scroll if already into view:
        return false;
    }

    if (elHeight < windowHeight) {
        offset = elOffset - ((windowHeight / 2) - (elHeight / 2));
    }
    else {
        offset = elOffset;
    }
    var speed = 400;
    $('html, body').animate({scrollTop: offset}, speed);
};