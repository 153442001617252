/**
 * Tests user agent using regex
 * @param {RegExp} regex
 * @returns {boolean}
 */
function testUserAgent(regex) {
    return navigator && navigator.userAgent && regex && regex.test(navigator.userAgent)
}

const isAppleDevice =testUserAgent(/(ipod|iphone|ipad)/i);

const isAndroid =  testUserAgent(/android/i);

const isNoCameraStream = testUserAgent(/(XiaoMi|MiuiBrowser|YaSearch|YaApp)/i);

const detectDeviceScreen = () => {
    if(window.screen && window.screen.width && window.screen.height) {
        return window.screen.width + "X" + window.screen.height
    }
}

export { isAppleDevice, isAndroid, isNoCameraStream, detectDeviceScreen };