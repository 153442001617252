import { cookies, ubeCookie } from "../../../util/cookie";
import { Block } from "./block";

export class IDXReg extends Block {
    run(key, state, cb) {
        const options = this.options;
        const target = this.initContainers('login', 'idx');
        const idxName = this.name.replace("-reg", "-idx");

        target.show().ube(idxName, {
            isTemplatePreloaded: false,
            sessionKey: state.sessionKey,
            loadFormDataFromSession: false,
            onFormLoad: function () {
                options.onFormLoad && options.onFormLoad(key);
            },
            data: {
                phone: state?.user?.phone || state.phone,
                agreeWithRules: false,
                ...state?.user
            },
            onSubmissionSuccess: function (submissionId, userId, result) {
                switch (result.event) {
                    case 'success':
                        cb({ idxRegResult: 'success' }, { sessionKey: result.data.sessionKey })
                }
            }
        })
    }

    close() {
        $('.ube-form-idx').hide();
    }
}
