import { cookies, ubeCookie } from "../../../util/cookie";
import { Block } from "./block";

export class ThankYou extends Block {
    run(key, state, cb) {
        $('.final-screen').show();
        this.options.finalContent(() => {
            cb({ sku: state.sku }, {})
        }, state);
    }

    close() {
        $('.final-screen').hide();
    }
}
