import $ from 'jquery';
(function(){

    function parseFileToBase64(fileSelector, callback, previewSelector) {
        var file = (fileSelector && fileSelector[0] && fileSelector[0].files) ?
            fileSelector[0].files[0] : null;

        function callbackBase64(base64data) {
            fileSelector.data("base64", base64data);
            if(previewSelector) previewSelector.attr("src", base64data);
            if(callback) callback();
        }

        function fallback(reader) {
            reader.addEventListener("load", function () {
                var base64data = reader.result;
                callbackBase64(base64data);
            }, false);
        }

        if (file) {
            var reader = new FileReader();

            reader.onload = function (readerEvent) {

                try {

                    var image = new Image();
                    image.onload = function (imageEvent) {

                        try {

                            // Resize the image
                            var canvas = document.createElement('canvas'),
                                max_size = 1600,
                                width = image.width,
                                height = image.height;
                            if (width > height) {
                                if (width > max_size) {
                                    height *= max_size / width;
                                    width = max_size;
                                }
                            } else {
                                if (height > max_size) {
                                    width *= max_size / height;
                                    height = max_size;
                                }
                            }
                            canvas.width = width;
                            canvas.height = height;
                            canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                            var resizedImage = canvas.toDataURL('image/jpeg');

                            callbackBase64(resizedImage);
                        }
                        catch (err) {
                            fallback(reader)
                        }


                    };
                    image.onerror = function () {
                        if(readerEvent.target.result.includes('data:application/pdf')) {
                            callbackBase64(readerEvent.target.result)
                        }
                    }
                    image.src = readerEvent.target.result;
                }
                catch (err) {
                    fallback(reader)
                }
            };

            reader.readAsDataURL(file);
        } else fileSelector.data("base64", null);
    }

    $.fn.ubeFileToBase64 = function (opts) {
        const fileSelector = $(this);
        const {callback, previewSelector} = opts;
        parseFileToBase64(fileSelector, callback, previewSelector);
    };

})();