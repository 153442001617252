import { cookies, ubeCookie } from "../../../util/cookie";
import { gaPush } from "../../../util/ga";
import { Block } from "./block";
import { getInfo } from "../../../util/login";

export class PhoneCheck extends Block {
    run(key, state, cb) {
        const options = this.options;
        const target = this.initContainers('login', 'phone-check');
        const phoneCheckName = this.name.replace("-reg", "-phone-check");
        const isFRCookieExists = ubeCookie(cookies.UBE_JWT_FACE_TOKEN) || false;
        const isTrial = options && options.trial;
        const isDocuments = options && options.documents;
        const isIDXEnabled = !state.faceAV;
        const formKey = this.name;

        const backToPhoneSend = () => {
            $('.ube-form-phone-check').hide();
            $('.ube-form-phone-send').show();
            $(".ube-phone-send-form [name=phone]").focus();
        }

        let isFormSubmitted = false;
        const isNewUser = !ubeCookie(cookies.UBE_WIDGET_REGISTER) && !ubeCookie(cookies.UBE_WIDGET_LOGIN);
        const isNewUserLabel = isNewUser ? "NewUser" : "ReturningUser";
        target.show().ube(phoneCheckName, {
            sessionKey: state.sessionKey,
            isTemplatePreloaded: false,
            data: {
                phone: state.phone
            },
            onFormLoad: function () {
                if ((isDocuments || isIDXEnabled) && !isFRCookieExists) {
                    $('.ube-av0-phone-send, .ube-av0-phone-check').show();
                    $('.ube-av1-phone-send, .ube-av1-phone-check').hide();
                } else {
                    $('.ube-av1-phone-send, .ube-av1-phone-check').show();
                    $('.ube-av0-phone-send, .ube-av0-phone-check').hide();
                }
                options.onFormLoad && options.onFormLoad(key);
            },
            onSubmissionSuccess: function (submissionId, userId, result) {
                const block = {
                    loginStatus: '',
                };
                const state = {
                    login: true,
                    sessionKey: result.data.sessionKey,
                    event: 'login',
                    result,
                    originalEvent: 'login'
                }
                switch (result.event) {
                    case 'cancel':
                    case 'close':
                        backToPhoneSend();
                        break;
                    case 'verified':
                        if (isFormSubmitted) break;
                        isFormSubmitted = true;

                        if (isIDXEnabled) {
                            block.loginStatus = 'verified-av0';
                            state.av = 0;
                            state.avStatus = 0;
                        } else {
                            block.loginStatus = 'verified-av1';
                            state.av = 1;
                            state.avStatus = 1;
                        };

                        cb(block, state)
                        break;
                    case 'loginSuccess':
                        if (isFormSubmitted) break;
                        isFormSubmitted = true;
                        ubeCookie(cookies.UBE_WIDGET_LOGIN, 'Y');
                        getInfo(result.data.sessionKey, formKey)
                            .done(response => {
                                const { av, trialAccess } = response;
                                const avStatus = +av;
                                state.avStatus = avStatus;
                                state.av = avStatus;
                                if (isIDXEnabled || isDocuments || isTrial) {
                                    if (avStatus === 1 || (isTrial && avStatus === 0 && trialAccess)) {
                                        console.log("UBE Login result", response);
                                        if (trialAccess) console.log("UBE :: user has trial access");
                                        block.loginStatus = 'loginSuccess-av1';
                                    } else {
                                        block.loginStatus = 'loginSuccess-av0';
                                    }
                                } else {
                                    block.loginStatus = 'loginSuccess-av' + avStatus;
                                }
                                cb(block, state);
                            })
                        break;
                }
            }
        });
    }

    close() {
        $('.ube-form-phone-check').hide();
    }
}
