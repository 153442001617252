import { cookies, ubeCookie } from "../util/cookie";

if (window.opener) {
    let params = new URL(document.location).searchParams;
    let payloadFromVk = params.get('payload');
    let payload;

    if(payloadFromVk) {
        payload = payloadFromVk;
    } else {
        let hash = window.location.hash.substring(1);
        let params = new URLSearchParams(hash);
        payload = JSON.stringify({
            token: params.get('access_token'),
            uuid: params.get('cid')
        });
    }

    if (payload) window.opener.postMessage(payload, '*');
    else window.opener.postMessage('ERROR AUTH', '*');
    window.close();
}

async function authBySocial({formKey, socialName, successCB, errorCB}) {
    try {
        const link = await getSocialLink(formKey, socialName);
        window.open(link, "mozillaWindow", "popup");

        window.addEventListener('message', async function (event) {
            if (event.origin !== window.location.origin) return;
            let data;

            try {
                data = JSON.parse(event.data);
            } catch {
                return;
            }

            const response = await fetch(`${$.ube.host}/api/social/auth/${socialName}/${formKey}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    token: data.token,
                    uuid: data.uuid,
                    ageVerifiedToken: ubeCookie(cookies.UBE_AGE_VERIFIED_TOKEN),
                    jwtFaceToken: ubeCookie(cookies.UBE_JWT_FACE_TOKEN),
                    isFaceSkipped: JSON.parse(sessionStorage.getItem("isFaceSkipped"))
                })
            });

            const result = await response.json();

            if(!response.ok) return errorCB(result);
            successCB(result);

        }, false);
    } catch (error) {
        errorCB(error);
    }
}

async function getSocialLink(formKey, socialName) {
    const response = await fetch(`${$.ube.host}/api/social/auth/getUrl/${formKey}/${socialName}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            redirectUrl: window.location.origin
        })
    });

    const data = await response.json();

    if(!response.ok) throw new Error(data.message);

    return data.url;
}

function getInfo(sessionKey, formKey) {
    return $.get(`${$.ube.host}/esb/${formKey}/avUserStatus?sessionKey=${sessionKey}`)
        .fail(() => console.log('error'));
}

export {getInfo, authBySocial};