export class Block {
    constructor(name, options, container, globalCtx) {
        this.name = name;
        this.options = options;
        this.container = container;
    }

    runBlock(key, state, cb) {
        if(this.options && this.options.onSwitchBlock) this.options.onSwitchBlock(key, state.previousBlock);

        this.run(key, state, (block, newState) => {
            this.close(state);
            cb(block, {
                ...state, 
                ...(newState || {}),
                previousBlock: key
            });
        })
    }

    initContainers(wrapperKey, innerKey) {
        let wrapper, inner;
        if (wrapperKey) {
            wrapper = $(`.ube-widget-${wrapperKey}-container`);
            if (!wrapper.length) 
                wrapper = $(`<div class="ube-widget-${wrapperKey}-container screen"></div>`).appendTo(this.container);
        }

        if (!innerKey) return wrapper;

        if (innerKey && wrapper && wrapper.length) {
            inner = $(`.ube-form-${innerKey}`);
            if (!inner.length)
                inner = $(`<div class="ube-form-${innerKey}"></div>`).appendTo(wrapper);
        }

        return inner;
    }


    close(state) {

    }
}

