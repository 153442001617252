import $ from 'jquery';
import {ubeHostFallBack} from '../util/fallback';
import {isAndroid, isAppleDevice} from '../util/device';

(function () {

    function getLink(sessionKey) {
        return $.ajax({
            url: $.ube.host + "/api/referral/link",
            headers: {"ube-session-key": sessionKey}
        });
    }

    function sendLink(sessionKey) {
        return $.ajax({
            url: $.ube.host + "/api/referral/link/send",
            headers: {"ube-session-key": sessionKey}
        });
    }

    $.fn.ubeWallet = function (sessionKey, callbackBefore, callbackAfter, callbackError) {
        const component = $(this);
        if (!sessionKey || sessionKey === "") throw new Error("wallet requires sessionKey as parameter");

        ubeHostFallBack();

        if(isAppleDevice || isAndroid) {
            callbackBefore("mobile");
            getLink(sessionKey).then(function(result) {
                if(!result || !result.value) callbackError("Отсутствует ссылка на скачивание карты. Попробуйте позже или обратитесь в Контакт Центр");
                else component.attr("href", result.value).attr("download", true).click(function(){
                    callbackAfter("mobile");
                });
            }).catch(function(e) {
                callbackError("Ошибка при запросе карты. Попробуйте позже или обратитесь в Контакт Центр");
                console.error(e);
            });
        } else {
            callbackBefore("desktop");
            component.off("click").click(function(e) {
                e.preventDefault();
                sendLink(sessionKey).then(function(result){
                    if(!result) callbackError("Ошибка при отправке карты. Попробуйте позже или обратитесь в Контакт Центр");
                    else if(result.value === false) callbackError("Отсутствует ссылка на скачивание карты. Попробуйте позже или обратитесь в Контакт Центр");
                    else if(result.phone === false) callbackError("Отсутствует номер телефона в профиле. Для отправки СМС заполните его");
                    else if(result.value === true) callbackAfter("desktop");
                    else callbackError("Ошибка при отправке карты. Попробуйте позже или обратитесь в Контакт Центр");
                }).catch(function(e) {
                    callbackError("Ошибка при отправке карты. Попробуйте позже или обратитесь в Контакт Центр");
                    console.error(e);
                });
                return false;
            });
        }
    };
})();
