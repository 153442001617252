import {Context} from "./Widget";
import RefCode from "./RefCode";
import Loyalty from "./Loyalty";
import {LeadTrialForm} from "./LeadTrialForm";

//const widgets = [new RefCode(), new Loyalty(), new LeadTrialForm()];
const widgets = [new RefCode(), new Loyalty()];

export const initWidgets = () => {
    const path = window.location.pathname;
    const host = window.location.hostname;
    const context = new Context(host, path);
    widgets.forEach(w => w.isDefinedAt(context) && w.launch(context))
};