import { Block } from "./block";

export class EmailOptin extends Block {
    run(key, state, cb) {
        const options = this.options;
        const target = $(`<div class="ube-widget-optin"></div>`).appendTo(this.container);

        target.show().ube('ube2-optin', {
            isTemplatePreloaded: false,
            data: state.result.data.data,
            template: state.result.data.template,
            onFormLoad: function() {
                options.onFormLoad && options.onFormLoad(key);
            },
            onSubmissionSuccess: function (submissionId, userId, result) {
                const response = {
                    event: result.event,
                    sessionKey: result.data.sessionKey,
                    userId
                };

                const block = { regStatus: '' };

                const state = { sessionKey: result.data.sessionKey };

                switch (result.event) {
                    case "alreadyVerified":
                        console.log("UBE Register AV1 result", response);

                        block.regStatus = 'alreadyVerified';
                        state.av = 1;
                        state.avStatus = 1;

                        cb(block, state);
                        break;
                    case "registered":
                        console.log("UBE Register result", response);

                        block.regStatus = 'registered';
                        state.av = 0;
                        state.avStatus = 0;

                        cb(block, state);
                        break;
                    case "close":
                        target.hide();
                        break;
                }
            }
        });
    }

    close() {
        $('.ube-widget-optin, .ube-form-register').hide();
    }
}