import $ from 'jquery';
import {isAndroid} from "../util/device";

$.fn.ubeMask = function (explicitInputMask, explicitPlaceholder, inputPlaceholder) {
    return this.each(function () {
        var field = $(this);
        var inputMask = explicitInputMask || $(this).attr("data-mask");
        var placeholder = explicitPlaceholder || $(this).attr("placeholder");
        if (inputMask && inputMask.length > 0) {
            field.addClass("ube-field-masked").attr("data-mask", inputMask);

            var maskFun = function (a, b) {
                if (isAndroid) {
                    field.attr('inputmode', 'numeric');
                }

                if($.ube && $.ube.fieldMask) return $.ube.fieldMask(field, a, b);

                    // COMMENTED TO TEST OUT ANDROID MASK

                    // else if (isAndroid) {
                    //     if (b && b.placeholder) field.attr("placeholder", b.placeholder);
                    //     try {
                    //         if (field.unmask) field.unmask();
                    //     } catch (e) {
                    //     }
                    //     try {
                    //         if (field.inputmask) field.inputmask('remove');
                    //     } catch (e) {
                    //     }
                    //     field.removeAttr("data-mask");
                    //     return false;
                // }
                else if (field.inputmask) return field.inputmask(a, b);
                else return field.mask(a, b);
            };

            if (placeholder && placeholder.length > 0) {
                field.attr("data-placeholder", placeholder);
                maskFun(inputMask, {placeholder: inputPlaceholder || placeholder});
            }
            else
                maskFun(inputMask, {});
        }
    });
};