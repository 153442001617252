import $ from 'jquery';
import {ubeHostFallBack} from './fallback';
import { cookies, ubeCookie } from './cookie';

/**
 * Get image file size from base64 format
 * @param base64Src {string}  
 * @return {number}
 */

function getImageSize(base64Src) {
    var endingOffset = 1;
    var encodingCoefficient = 0.75  // base64 encodes 3 bytes of binary data on 4 chars 
    if (base64Src.endsWith('==')) {
        endingOffset = 2
    }
    var sizeInBytes = (base64Src.length - endingOffset) * encodingCoefficient;
    var sizeInKB = sizeInBytes / 1024;
    return sizeInKB;
}

/**
 * Resize image based on dimensions restrictions 
 * @param image {Object}
 * @param image.width {number} 
 * @param image.height {number}
 */

function resizeImage(image, maxWidth, maxHeight, maxPixels) {
    if (image.width < maxWidth && image.height < maxHeight && image.width * image.height < maxPixels) return true;
    var imageProportion = image.width / image.height;

    if (image.width * image.height > maxPixels) {
        image.height = Math.sqrt(maxPixels / imageProportion);
        image.width = image.height * imageProportion;
    }
    if (image.width > image.height) {
        if (image.width > maxWidth) {
            image.height = image.height * (maxWidth / image.width);
            image.width = maxWidth;
        }
    } else {
        if (image.height > maxHeight) {
            image.width = image.width * (maxHeight / image.height);
            image.height = maxHeight;
        }
    };

    return false;
}

function imageDataToBlob(imageData) {
    var block = imageData.split(";");
    // Get the content type of the image
    var contentType = block[0].split(":")[1];// In this case "image/gif"
    // get the real base64 content of the file
    var realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."
    // Convert it to a blob to upload
    var blob = b64toBlob(realData, contentType);
    return blob;
}

/**
 * Convert a base64 string in a Blob according to the data and contentType.
 *
 * @param b64Data {String} Pure base64 string without contentType
 * @param contentType {String} the content type of the file i.e (image/jpeg - image/png - text/plain)
 * @param sliceSize {Int} SliceSize to process the byteCharacters
 * @see http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
 * @return Blob
 */
function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, {type: contentType});
    return blob;
}

/**
 * Sends request ELK log with ACS check results
 * @param {String} type - photo creation type
 * @param {Boolean} result - photo verification result 
 * @param {Boolean} error - error for elk 
 * @param {String} errorText - error message from default vendor
 * @param {{initial: {width: Number, height: Number, sizeKB: Number}, resized: {width: Number, height: Number, sizeKB: Number}}} photoData - aggregated physical photo data 
 * @param {String} image - image in base64 
 * @param {String} formKey - Face Reco form key  
 */

function logACSResultToELK(type, result, error, errorText, photoData, image, formKey, token) {
    ubeHostFallBack();
    var data = {
        event: 'ACSVerification',
        type,
        result,
        error,
        image, 
        formKey,
    };

    if (!result || error) data.errorText = errorText;
    if (token) data.faceToken = token;

    data.photoData = {
        initial: {
            width: photoData.initial.width,
            height: photoData.initial.height,
            sizeKB: photoData.initial.sizeKB
        },
        resized: {
            width: photoData.resized.width,
            height: photoData.resized.height,
            sizeKB: photoData.resized.sizeKB
        },
    }

    $.ajax({
        url: $.ube.host + '/api/log/face',
        method: 'POST',
        data: JSON.stringify(data),
        processData: false,
        cache: false,
        dataType: 'json',
        crossDomain: true,
        contentType: 'application/json',
        error: function(err) {
            console.log('UBE :: error sending data to ELK', err);
        },
        success: function(data) {
            if(data.jwt) ubeCookie(cookies.UBE_JWT_FACE_TOKEN, data.jwt);
            console.log('UBE :: data successfully sent');
        }
    })
}

export { resizeImage, getImageSize, imageDataToBlob, logACSResultToELK }