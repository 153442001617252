import { AV } from "./blocks/av";
import { Block } from "./blocks/block";
import { Content } from "./blocks/content";
import { Face } from "./blocks/face";
import { IDXReg } from "./blocks/idxReg";
import { PhoneCheck } from "./blocks/phoneCheck";
import { PhoneSend } from "./blocks/phoneSend";
import { Redirect } from "./blocks/redirect";
import { Registration } from "./blocks/registration";
import { Start } from "./blocks/start";
import { ThankYou } from "./blocks/thankYou";
import { FailContent } from "./blocks/failContent";
import { EmailOptin } from "./blocks/emailOptin";
import { Survey } from "./blocks/survey";

/**
 * @type {Object.<string, Block>}
 */
const MAPPING = {
    input: Start,
    faceNode: Face,
    contentNode: Content,
    failContentNode: FailContent,
    smsPhoneSendNode: PhoneSend,
    smsPhoneCheckNode: PhoneCheck,
    IDXNode: IDXReg,
    regNode: Registration,
    emailOptinNode: EmailOptin,
    avNode: AV,
    surveyNode: Survey,
    thankYouPageNode: ThankYou,
    output: Redirect
};

/**
 * Creates new class instance
 * @param {String} key 
 * @returns {Block}
 */
export function create(key, name, options, container) {
    const TargetClass = MAPPING[key];
    return new TargetClass(name, options, container);
}